import axios from "../helpers/axios";

export const getProducts = async () => {
  return axios
    .get("api/products/")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getProductById = async (id) => {
  return axios
    .get(`/api/products/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getProductsBySeller = async (id) => {
  return axios
    .get(`/api/seller_products/seller/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getSellersByProduct = async (id) => {
  return axios
    .get(`/api/seller_products/product/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const createProduct = async (params) => {
  return axios
    .post("/api/products", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const removeProduct = async (id) => {
  return axios
    .delete(`/api/products/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const editProduct = async (id, params) => {
  return axios
    .put(`/api/products/${id}`, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const searchProduct = async (search) => {
  return axios
    .post("/api/products/search", { search })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addProductToSeller = async (params) => {
  return axios
    .post("/api/seller_products", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const removeProductFromSeller = async (id) => {
  return axios
    .delete(`/api/seller_products/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
