import React, { useState, useEffect } from "react";

import { getUser, deleteUser } from "../../api/user.api";
import {
  Table,
  Button,
  Modal,
  Row,
  Col,
  Typography,
  Space,
  Tag,
  Input,
  Divider,
} from "antd";
import Swal from "sweetalert2";
import DefaultLoader from "../Shared/loader";

import { EyeOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

export const UserTable = () => {
  const [viewData, setViewData] = useState({});
  const [userData, setUserData] = useState({});
  const [openView, setOpenView] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const [userRow, setUserRow] = useState([]);
  const [searchData, setSearchData] = useState([]); // This is the data that will be filtered
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const handleView = async (data) => {
    setViewData(data);
    setOpenView(true);
  };

  const handleQuotation = async (data) => {
    setUserData(data);
    setOpenUser(true);
  };

  const handleDelete = async (id, name) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "deleteButton1",
          cancelButton: "veiwButton1",
        },
        //buttonsStyling: false,
      });

      const result = await swalWithBootstrapButtons.fire({
        title: "Are you sure?",
        text: "You want to delete " + name + "?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        // Make the axios delete request
        const response = await deleteUser(id);
        if (response.status === 200) {
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Your file has been deleted.",
            "success"
          );
          // Update the table
          const newUserRow = userRow.filter((row) => row._id !== id);
          setUserRow(newUserRow);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire("Cancelled", "Done", "error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const actionColumn = [
    {
      dataIndex: "email",
      title: "Email",
      sorter: (a, b) => a.email.length - b.email.length,
      key: "email",
      ellipsis: true,
    },
    {
      dataIndex: "firstName",
      title: "First name",
      sorter: (a, b) => a.firstName.length - b.firstName.length,
      key: "firstName",
      ellipsis: true,
    },
    {
      dataIndex: "lastName",
      sorter: (a, b) => a.lastName.length - b.lastName.length,
      title: "Last Name",
      key: "lastName",
      ellipsis: true,
    },

    {
      dataIndex: "rfqCredit",
      title: "Rfq Credit",
      key: "rfqCredit",
      width: 110,
    },

    {
      dataIndex: "isVIP",
      title: "Vip Elibility",
      key: "isVIP",
      render: (_, params) => {
        return (
          <Tag color={params.isVIP === false ? "magenta" : "green"}>
            {params.isVIP.toString()}
          </Tag>
        );
      },
    },

    {
      dataIndex: "isVerfied",
      title: "Verification",
      key: "_id",
      render: (_, params) => {
        return (
          <Tag color={params.isVerfied === false ? "magenta" : "green"}>
            {params.isVerfied.toString()}
          </Tag>
        );
      },
    },

    // {
    //   dataIndex: "createdAt",
    //   title: "CREATED AT",
    //   key: "createdAt",
    //   sorter: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
    //   defaultSortOrder: "ascend",
    //   render(_, params) {
    //     return (
    //       <div>
    //         <p>{formatDate(params.createdAt)}</p>
    //       </div>
    //     );
    //   },
    // },

    {
      dataIndex: "action",
      title: "Action",
      key: "_id",
      render: (_, params) => {
        return (
          <>
            <Button
              size="small"
              shape="circle"
              style={{ color: "green" }}
              onClick={() => {
                handleView(params);
              }}
            >
              <EyeOutlined style={{ color: "green" }} />
            </Button>

            <Divider type="vertical" />

            <Button
              size="small"
              shape="circle"
              style={{ color: "red" }}
              onClick={() => {
                handleDelete(params._id, params.email);
              }}
              danger
            >
              <DeleteOutlined style={{ color: "red" }} />
            </Button>
            <Divider type="vertical" />
            <Button
              size="small"
              shape="circle"
              style={{ color: "blue" }}
              onClick={() => {
                handleQuotation(params);
              }}
            >
              <EditOutlined style={{ color: "blue" }} />
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUser();
        const data = await response.data;
        setUserRow(data.rows);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return <DefaultLoader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Divider orientation="left">
        <Title variant="h3">List of Users</Title>
      </Divider>
      {/* Another modal for viewing all details of the selected data */}

      <Modal
        open={openView}
        onCancel={() => {
          setOpenView(false);
        }}
        footer={null}
        title={"View Details"}
        width={800}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Email</Text>
                <Text>{viewData?.email}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Mobile Number</Text>
                <Text>{viewData?.mobileNumber}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Full name</Text>
                <Text>
                  {viewData?.firstName} {viewData?.lastName}
                </Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Rfq Credit</Text>
                <Text>{viewData?.rfqCredit}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Vip Elibility</Text>
                <Text>{viewData?.isVIP}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Account Verification</Text>
                <Text>{userData?.isVerfied}</Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>
      <Modal
        open={openUser}
        onCancel={() => {
          setOpenUser(false);
        }}
        footer={null}
        title={"Set Quotation"}
        width={800}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Email</Text>
                <Text>{userData?.email}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Full name</Text>
                <Text>
                  {userData?.firstName} {userData?.lastName}
                </Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Rfq Credit</Text>
                <Text>{userData?.rfqCredit}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Vip Elibility</Text>
                <Text>{userData?.isVIP}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Account Verification</Text>
                <Text>{userData?.isVerfied}</Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>
      <Input
        style={{ width: "600px" }}
        placeholder="Search User"
        value={searchValue}
        allowClear
        onChange={(e) => {
          const currValue = e.target.value;
          setSearchValue(currValue);
          const filteredData = userRow.filter((entry) => {
            if (
              entry.email.toLowerCase().includes(currValue.toLowerCase()) ||
              entry.firstName.toLowerCase().includes(currValue.toLowerCase()) ||
              entry.lastName.toLowerCase().includes(currValue.toLowerCase())
            ) {
              return entry;
            }
            return null;
          });
          setSearchData(filteredData);
        }}
      />
      <br />
      <Table
        columns={actionColumn}
        dataSource={searchData.length > 0 ? searchData : userRow}
        pagination={{ pageSize: 5 }}
        rowKey="_id"
      />
    </>
  );
};
