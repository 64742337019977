// generate a 404 page in jsx without frameworks
import "./404.scss";

import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="heehee">
      <h1 className="h11">404</h1>

      <h2>Sorry It looks like..</h2>
      <p className="p1">The page you tried to browse cannot be found</p>
      <div className="wrapper">
        <Link className="a1" to="/login">
          Go Back!
        </Link>
      </div>
      <div class="travolta"></div>
    </div>
  );
};

export default NotFound;
