import axios from "axios";

let instance = axios.create({
  baseURL: "https://api.weleadants.com/",
  //baseURL: "http://localhost:3000/",
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === 403 || status === 401) {
      // remove token and redirect to login
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default instance;
