import React, { useState, useEffect, useRef, useMemo } from "react";

import { getSeller } from "../../api/user.api";
import {
  Table,
  Button,
  Modal,
  Typography,
  Space,
  Form,
  Input,
  Spin,
  Select,
  Divider,
} from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import DefaultLoader from "../Shared/loader";
import { createSeller, editSeller, removeSeller } from "../../api/seller.api";
import debounce from "lodash/debounce";
import _ from "lodash";
import {
  addProductToSeller,
  createProduct,
  getProductsBySeller,
  removeProductFromSeller,
  searchProduct,
} from "../../api/product.api";
import productSearchStore from "../../store/product_search.store";

const { Title } = Typography;
const { confirm } = Modal;

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const setProducts = productSearchStore((state) => state.setProducts);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      if (value === "") return;
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        const mappedData = newOptions.data.map((entry) => {
          return {
            value: entry._id,
            label: entry.name,
          };
        });
        setProducts(newOptions.data);
        setOptions(mappedData);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

export const SellerTable = () => {
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openCreateProduct, setOpenCreateProduct] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openProducts, setOpenProducts] = React.useState(false);
  const [userRow, setUserRow] = useState([]);
  const [currentEditId, setCurrentEditId] = useState("a");
  const [sellerId, setSellerId] = useState("");
  const [searchData, setSearchData] = useState([]); // This is the data that will be filtered
  const [isLoading, setIsLoading] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [error, setError] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [productSearchValue, setProductSearchValue] = useState([]);
  const [sellerProducts, setSellerProducts] = useState([]);
  const [productTableLoading, setProductTableLoading] = useState(false);
  const [currentSellerData, setCurrentSellerData] = useState({});

  const editForm = useRef(null);
  const searchedProducts = productSearchStore(
    (state) => state.searchedProducts
  );

  const handleOpenProducts = async (id) => {
    setSellerProducts([]);
    setSellerId(id);
    setOpenProducts(true);
    setProductTableLoading(true);
    const products = await getProductsBySeller(id);
    setSellerProducts(products.data);
    setProductTableLoading(false);
  };

  const handleAddProductToSeller = async (data) => {
    return await addProductToSeller(data);
  };

  const handleDelete = async (id) => {
    await removeSeller(id);
    const newData = userRow.filter((entry) => entry._id !== id);
    setUserRow(newData);
  };

  const handleRemoveProductFromSeller = async (id) => {
    const newData = sellerProducts.filter((entry) => entry._id !== id);
    setSellerProducts(newData);
    await removeProductFromSeller(id);
  };

  const handleCreateSeller = async (values) => {
    try {
      setButtonLoader(true);
      await createSeller(values);
      setUserRow((prevData) => [...prevData, values]);
      setButtonLoader(false);
      setOpenCreate(false);
    } catch (error) {
      setError(error);
      setButtonLoader(false);
    }
  };

  const handleCreateProduct = async (values) => {
    try {
      setButtonLoader(true);
      await createProduct(values);
      setButtonLoader(false);
      setOpenCreateProduct(false);
    } catch (error) {
      setError(error);
      setButtonLoader(false);
    }
  };

  const handleEditSeller = async (values) => {
    setButtonLoader(true);
    await editSeller(currentEditId, values);
    const newData = userRow.map((entry) => {
      if (entry._id === currentEditId) {
        return { ...entry, ...values };
      }
      return entry;
    });
    setUserRow(newData);
    setOpenEdit(false);
    setButtonLoader(false);
  };

  const productsColumn = [
    {
      dataIndex: "productName",
      title: "Name",
      sorter: (a, b) => a.name?.length - b.name?.length,
      key: "productName",
    },
    {
      dataIndex: "description",
      title: "Description",
      key: "description",
    },
    {
      dataIndex: "brand",
      sorter: (a, b) => a.email?.length - b.email?.length,
      title: "Brand",
      key: "brand",
    },

    {
      dataIndex: "dimension",
      title: "Dimensions",
      key: "dimension",
    },

    {
      dataIndex: "weight",
      title: "Weight",
      key: "weight",
    },
    {
      dataIndex: "productLink",
      title: "Product Link",
      key: "productLink",
      render: (_, params) => {
        // Visit text
        return params.productLink ? (
          <a target="_blank" rel="noreferrer" href={params.productLink}>
            Visit
          </a>
        ) : null;
      },
    },
    {
      dataIndex: "action",
      title: "Action",
      key: "_id",
      render: (_, params) => {
        return (
          <Space direction="vertical">
            <Button
              size="small"
              type="primary"
              danger
              onClick={() => {
                confirm({
                  title: "Do you want to delete product from seller?",
                  icon: <ExclamationCircleFilled />,
                  onOk() {
                    handleRemoveProductFromSeller(params._id);
                  },
                });
              }}
            >
              Remove
            </Button>
          </Space>
        );
      },
    },
  ];

  const actionColumn = [
    {
      dataIndex: "name",
      title: "Name",
      sorter: (a, b) => a.name?.length - b.name?.length,
      key: "name",
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile Number",
      key: "mobileNumber",
    },
    {
      dataIndex: "email",
      sorter: (a, b) => a.email?.length - b.email?.length,
      title: "Email",
      key: "email",
    },

    {
      dataIndex: "industryType",
      sorter: (a, b) => a.industryType?.length - b.industryType?.length,
      title: "Industry Type",
      key: "industryType",
    },

    {
      dataIndex: "address",
      title: "Address",
      key: "address",
    },

    {
      dataIndex: "action",
      title: "Action",
      key: "_id",
      render: (_, params) => {
        return (
          <>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                handleOpenProducts(params._id);
                setCurrentSellerData(params);
              }}
            >
              Products
            </Button>
            <Divider type="vertical" />
            <Button
              size="small"
              type="primary"
              onClick={() => {
                setOpenEdit(true);
                setCurrentEditId(params._id);
                setTimeout(() => {
                  editForm.current.setFieldsValue(params);
                }, 100);
              }}
            >
              Edit
            </Button>
            <Divider type="vertical" />
            <Button
              size="small"
              type="primary"
              danger
              onClick={() => {
                confirm({
                  title: "Do you want to delete seller?",
                  icon: <ExclamationCircleFilled />,
                  onOk() {
                    handleDelete(params._id);
                  },
                });
              }}
            >
              Remove
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSeller();
        const data = await response.data;
        setUserRow(data.rows);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return <DefaultLoader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Title variant="h3">List of Suppliers</Title>

      <Modal
        open={openCreate}
        onCancel={() => {
          setOpenCreate(false);
        }}
        footer={null}
        title={"Create Seller"}
        // width={800}
      >
        <>
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            style={{ maxWidth: 600 }}
            onFinish={handleCreateSeller}
          >
            <Form.Item
              label="Seller Name"
              name="name"
              rules={[
                { required: true, message: "Please input your Seller Name!" },
              ]}
            >
              <Input placeholder="Seller Name" />
            </Form.Item>
            <Form.Item label="Mobile Number" name="mobileNumber">
              <Input placeholder="Mobile Number" />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input placeholder="Email" type="email" />
            </Form.Item>
            <Form.Item label="Industry Type" name="industryType">
              <Input placeholder="Industry Type" />
            </Form.Item>
            <Form.Item label="Address" name="address">
              <Input placeholder="Address" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={buttonLoader}>
                Create
              </Button>
            </Form.Item>
          </Form>
        </>
      </Modal>
      <Modal
        open={openCreateProduct}
        onCancel={() => {
          setOpenCreateProduct(false);
        }}
        footer={null}
        title={"Create Product"}
        // width={800}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            style={{ maxWidth: 600 }}
            onFinish={handleCreateProduct}
          >
            <Form.Item
              label="Product Name"
              name="name"
              rules={[
                { required: true, message: "Please input your Product Name!" },
              ]}
            >
              <Input placeholder="Product Name" />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <Input placeholder="Description" />
            </Form.Item>
            <Form.Item label="Brand" name="brand">
              <Input placeholder="Brand" />
            </Form.Item>
            <Form.Item label="Dimensions" name="dimension">
              <Input placeholder="Dimensions" />
            </Form.Item>
            <Form.Item label="Weight" name="weight">
              <Input placeholder="Weight" />
            </Form.Item>
            <Form.Item label="Link" name="productLink">
              <Input placeholder="Link" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={buttonLoader}
                block
              >
                Create
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Modal>
      <Modal
        open={openProducts}
        onCancel={() => {
          setOpenProducts(false);
        }}
        footer={null}
        title={"Products"}
        width={800}
      >
        <>
          <div style={{ width: "100%" }}>
            <DebounceSelect
              // mode="multiple"
              showSearch
              value={productSearchValue}
              placeholder="Add Products"
              fetchOptions={searchProduct}
              onChange={async (newValues) => {
                setProductSearchValue(null);
                const selectedProduct = searchedProducts.find(
                  (entry) => entry._id === newValues.value
                );

                const productClone = _.cloneDeep(selectedProduct);
                const currentSellerDataClone = _.cloneDeep(currentSellerData);
                delete productClone._id;
                delete currentSellerDataClone._id;
                setProductTableLoading(true);
                const res = await handleAddProductToSeller({
                  ...productClone,
                  ...currentSellerDataClone,
                  productName: selectedProduct.name,
                  sellerName: currentSellerDataClone.name,
                  sellerId: sellerId,
                  productId: selectedProduct._id,
                });

                setSellerProducts((prevData) => [
                  ...prevData,
                  {
                    ...selectedProduct,
                    productName: selectedProduct.name,
                    _id: res.data._id,
                  },
                ]);
                setProductTableLoading(false);
              }}
              style={{ width: "300px" }}
            />
            <Button
              type="primary"
              style={{ float: "right" }}
              onClick={() => {
                setOpenCreateProduct(true);
              }}
            >
              Create New Product
            </Button>
          </div>
          <Divider />

          <Table
            dataSource={sellerProducts}
            loading={productTableLoading}
            columns={productsColumn}
          ></Table>
        </>
      </Modal>
      <Modal
        open={openEdit}
        onCancel={() => {
          setOpenEdit(false);
        }}
        footer={null}
        title={"Edit Seller"}
        // width={800}
      >
        <>
          <Form
            ref={editForm}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            style={{ maxWidth: 600 }}
            onFinish={handleEditSeller}
          >
            <Form.Item
              label="Seller Name"
              name="name"
              rules={[
                { required: true, message: "Please input your Seller Name!" },
              ]}
            >
              <Input placeholder="Seller Name" />
            </Form.Item>
            <Form.Item label="Mobile Number" name="mobileNumber">
              <Input placeholder="Mobile Number" />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input placeholder="Email" type="email" />
            </Form.Item>
            <Form.Item label="Industry Type" name="industryType">
              <Input placeholder="Industry Type" />
            </Form.Item>
            <Form.Item label="Address" name="address">
              <Input placeholder="Address" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={buttonLoader}
                block
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </>
      </Modal>
      <div>
        <Input
          style={{ width: "300px" }}
          placeholder="Search"
          value={searchValue}
          onChange={(e) => {
            const currValue = e.target.value;
            setSearchValue(currValue);
            const filteredData = userRow.filter((entry) => {
              if (
                entry.name.toLowerCase().includes(currValue.toLowerCase()) ||
                entry.email.toLowerCase().includes(currValue.toLowerCase()) ||
                entry.address.toLowerCase().includes(currValue.toLowerCase())
              ) {
                return entry;
              }
              return null;
            });
            setSearchData(filteredData);
          }}
        />

        <Button
          style={{ float: "right" }}
          type="primary"
          onClick={() => {
            setOpenCreate(true);
          }}
        >
          Create Seller
        </Button>
      </div>
      <Divider />
      <Table
        columns={actionColumn}
        dataSource={searchData?.length > 0 ? searchData : userRow}
        pagination={{ pageSize: 5 }}
        rowKey="_id"
      />
    </>
  );
};
