import React, { useState, useEffect } from "react";
import {
  getshipped,
  vieworder,
  userRFQ,
  getrfqAddress,
  updateShipped,
  viewship,
} from "../../api/requestForQuotation.api";
import { ManualTrackingAdd } from "../../api/tracking.api";
//import { returnedOrder } from "../../api/order.api";
import {
  Table,
  Button,
  Tag,
  Modal,
  Row,
  Col,
  Typography,
  Space,
  Image,
  Input,
  Divider,
  DatePicker,
} from "antd";
import Swal from "sweetalert2";
import { capitalizeFirstLetter, formatDate } from "../../helpers/utils";
import DefaultLoader from "../Shared/loader";
import {
  EyeOutlined,
  UserOutlined,
  EnvironmentOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
const { Text } = Typography;

export const ShippedData = () => {
  const [viewData, setViewData] = useState({});
  const [openView, setOpenView] = React.useState(false);
  const [openQuotation, setOpenQuotation] = React.useState(false);
  const [rfqRow, setRfqRow] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderData, setOrderData] = useState({});

  //view the user of the RfQ
  const [userData, setUserData] = useState({});
  const [openUser, setOpenUser] = React.useState(false);

  //address
  const [address, setAddress] = useState({});

  //search
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]); // This is the data that will be filtered

  const [manualTracking, setManualTracking] = useState(false);
  const [manualTrackingId, setManualTrackingId] = useState("");
  const [manualTrackingStatus, setManualTrackingStatus] = useState("");
  const [manualTrackingTitle, setManualTrackingTitle] = useState("");
  const [manualTrackingDescription, setManualTrackingDescription] =
    useState("");
  const [manualTrackingDate, setManualTrackingDate] = useState("");

  const handleAddress = async (addressId) => {
    const response = await getrfqAddress(addressId);
    if (response.status === 200) {
      setAddress(response.data);
    }
  };

  const handleUser = async (id) => {
    const response = await userRFQ(id);
    if (response.status === 200) {
      setUserData(response.data);
      setOpenUser(true);
    }
  };

  const handleView = async (data) => {
    setViewData(data);
    setOpenView(true);
  };

  const handleOrder = async (id) => {
    const response = await vieworder(id);
    if (response.status === 200) {
      setOrderData(response.data);
      setOpenQuotation(true);
    }
  };

  //view the user of the RfQ

  const [shipData, setViewShipData] = useState({});
  const handleViewship = async (id) => {
    const response = await viewship(id);
    if (response.status === 200) {
      setViewShipData(response.data);
    }
  };

  //handle the add shipping HEE HEE
  const [track, setTrack] = useState("");
  const [link, setLink] = useState("");
  const [ship, setShipData] = useState({});
  const [openShip, setOpenShip] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const handleShip = async (data) => {
    setShipData(data);
    setTrack(data.track);
    setLink(data.link);
    setOpenShip(true);
  };

  const resetShip = () => {
    setTrack("");
    setLink("");
  };

  const handleShippingSubmit = async () => {
    const params = {
      rfqId: ship._id,
      tracking_no: track,
      link: link,
    };

    setLoading(true);
    try {
      const response = await updateShipped(params);
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Shipping successfully Updated!",
        });
        setOpenShip(false);
        resetShip();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const resetManual = () => {
    setManualTrackingId("");
    setManualTrackingStatus("");
    setManualTrackingTitle("");
    setManualTrackingDescription("");
    setManualTrackingDate("");
  };

  const handleManualShippingSubmit = async () => {
    setLoading(true);
    try {
      const response = await ManualTrackingAdd(
        manualTrackingId,
        manualTrackingStatus,
        manualTrackingTitle,
        manualTrackingDescription,
        manualTrackingDate
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Shipping successfully Updated!",
        });
        setManualTracking(false);
        resetManual();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const actionColumn = [
    {
      dataIndex: "rfq",
      title: "Image",
      key: "rfq",
      render: (_, params) => {
        return (
          <Image
            src={params.image}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://antswelead.sgp1.digitaloceanspaces.com/internal_files/335623101_583997373674210_5297548643792796750_n.jpg";
            }}
            alt="img"
            style={{ width: "80px", height: "60px", objectFit: "cover" }}
          />
        );
      },
    },
    {
      dataIndex: "prodName",
      title: "PRODUCT NAME",
      key: "prodName",
    },
    { dataIndex: "targetqty", title: "QUANTITY", key: "targetqty" },
    {
      dataIndex: "deadline",
      title: "DEADLINE",
      key: "deadline",
      render(_, params) {
        return (
          <div>
            <p>{formatDate(params.deadline)}</p>
          </div>
        );
      },
    },

    {
      dataIndex: "status",
      title: "Status",
      key: "_id",
      render: (_, params) => {
        return (
          <Tag color={params.status === "pending" ? "magenta" : "green"}>
            {capitalizeFirstLetter(params.status)}
          </Tag>
        );
      },
    },

    {
      dataIndex: "orderId",
      title: "",
      width: 0,
      key: "orderId",
      render: () => null,
    },

    {
      dataIndex: "action",
      title: "Action",
      key: "_id",
      render: (_, params) => {
        return (
          <>
            <Button
              size="large"
              shape="circle"
              onClick={() => {
                handleView(params);
                handleAddress(params.deliveryAddressId);
              }}
            >
              <EyeOutlined style={{ color: "green" }} />
            </Button>
            <Divider type="vertical" />
            <Button
              size="large"
              shape="circle"
              onClick={() => {
                handleUser(params._id);
              }}
            >
              <UserOutlined style={{ color: "skyblue" }} />
            </Button>
            <Divider type="vertical" />
            <Button
              size="large"
              shape="circle"
              onClick={() => {
                handleOrder(params.orderId);
                handleAddress(params.deliveryAddressId);
              }}
            >
              <FileDoneOutlined style={{ color: "#8B8000" }} />
            </Button>
            <Divider type="vertical" />
            <Button
              size="large"
              shape="circle"
              onClick={() => {
                handleViewship(params._id);
                handleShip(params);
              }}
            >
              <EnvironmentOutlined />
            </Button>

            <Divider type="vertical" />
            <Button
              size="large"
              shape="circle"
              onClick={() => {
                setManualTrackingId(params._id);
                setManualTracking(true);
              }}
            >
              <EnvironmentOutlined style={{ color: "green" }} />
            </Button>
          </>
        );
      },
    },
  ];
  // const [manualTracking, setManualTracking] = useState(false);
  // const [manualTrackingId, setManualTrackingId] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getshipped();
        const data = await response.data;
        setRfqRow(data.Requests);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return <DefaultLoader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      {/* Another modal for viewing all details of the selected data */}
      <Modal
        open={openView}
        onCancel={() => {
          setOpenView(false);
        }}
        footer={null}
        title={"View Details"}
        width={800}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Image src={viewData?.image} alt="img" width={300} />

            <Space direction="vertical">
              <Text strong></Text>
              <Text strong>Address</Text>
              {address ? (
                <Text>
                  {address.regionName}, {address.province},{" "}
                  {address.municipalitName}, {address.baranggayName},{" "}
                  {address.street}, {address.zipcode}
                </Text>
              ) : (
                <Text>No address available</Text>
              )}
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Product Name</Text>
                <Text>{viewData?.prodName}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Target Quantity</Text>
                <Text>{viewData?.targetqty}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Deadline</Text>
                <Text>{viewData?.deadline}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Status</Text>
                <Text>{viewData?.status}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Description</Text>
                <Text>{viewData?.description}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Specifications</Text>
                <Text>{viewData?.specs}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Files</Text>
                <a href={viewData?.files} target="_blank" rel="noreferrer">
                  {viewData?.files?.split("/").pop()}
                </a>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>

      <Modal
        open={openQuotation}
        onCancel={() => {
          setOpenQuotation(false);
          setOrderData();
        }}
        title={"Set Quotation"}
        width={800}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenQuotation(false);
              setOrderData();
            }}
          >
            Back
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Image src={orderData?.image} alt="img" width={300} />
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Delivery Address</Text>
                <Text>
                  {address ? (
                    <Text>
                      {address.regionName}, {address.province},{" "}
                      {address.municipalitName}, {address.baranggayName},{" "}
                      {address.street}, {address.zipcode}
                    </Text>
                  ) : (
                    <Text>No address available</Text>
                  )}
                </Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Product Name</Text>
                <Text>{capitalizeFirstLetter(orderData?.prod_name)}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Target Quantity</Text>
                <Text>{orderData?.quantityavailable}</Text>
              </Space>

              <Space direction="vertical">
                <Text strong>Files</Text>
                <a href={orderData?.files} target="_blank" rel="noreferrer">
                  {orderData?.files?.split("/").pop()}
                </a>
              </Space>
              <Space direction="vertical">
                <Text strong>Comment</Text>
                <Text>{orderData?.comment}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Price</Text>
                <Text>{orderData?.price}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Shipping</Text>
                <Text>{orderData?.shipping}</Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>

      {/* User Quotation */}
      <Modal
        open={openUser}
        onCancel={() => {
          setOpenUser(false);
          setUserData();
        }}
        title={"User Details"}
        width={800}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenUser(false);
              setUserData();
            }}
          >
            Back
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Name</Text>
                <Text>
                  {userData?.firstName} {userData?.lastName}
                </Text>
              </Space>
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Contact Details</Text>
                <Text>{userData?.email}</Text>
                <Text>{userData?.mobileNumber}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Vip Status</Text>
                <Text>
                  {" "}
                  {userData?.isVIP === false ? (
                    <span style={{ color: "red" }}>No</span>
                  ) : (
                    <span style={{ color: "green" }}>Yes</span>
                  )}
                </Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>

      <Modal
        open={openShip}
        onCancel={() => {
          setOpenShip(false);
          resetShip();
        }}
        title={"Set Shipping Details"}
        width={800}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenShip(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleShippingSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Tracking Number</Text>
                <Input
                  value={track}
                  onChange={(newtrack) => {
                    setTrack(newtrack.target.value);
                  }}
                ></Input>
              </Space>

              <Space direction="vertical">
                <Text strong>Link</Text>
                <Input
                  value={link}
                  onChange={(newlink) => {
                    setLink(newlink.target.value);
                  }}
                ></Input>
              </Space>
            </Space>
          </Col>

          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Shipping Details</Text>
                <Text>{shipData.tracking_no} </Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Link</Text>
                <Text>{shipData.link} </Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>

      <Modal
        open={manualTracking}
        onCancel={() => {
          setManualTracking(false);
        }}
        title={"Set Manual Shipping Update"}
        width={800}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setManualTracking(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleManualShippingSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Tracking Status</Text>
                <Input
                  value={manualTrackingStatus}
                  onChange={(e) => {
                    setManualTrackingStatus(e.target.value);
                  }}
                ></Input>
              </Space>
              <Space direction="vertical">
                <Text strong>Tracking Title</Text>
                <Input
                  value={manualTrackingTitle}
                  onChange={(e) => {
                    setManualTrackingTitle(e.target.value);
                  }}
                ></Input>
              </Space>
            </Space>
          </Col>

          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Tracking Description</Text>
                <Input
                  value={manualTrackingDescription}
                  onChange={(e) => {
                    setManualTrackingDescription(e.target.value);
                  }}
                ></Input>
              </Space>
              <Space direction="vertical">
                <Text strong>Tracking Date</Text>
                <DatePicker
                  onChange={(date, dateString) => {
                    setManualTrackingDate(dateString);
                  }}
                ></DatePicker>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>

      {/* search */}
      <Text>
        <Input
          style={{ margin: "20px", width: "600px" }}
          placeholder="Search Product Name"
          value={searchValue}
          onChange={(e) => {
            const currValue = e.target.value;
            setSearchValue(currValue);
            const filteredData = rfqRow.filter((entry) => {
              if (
                entry.prodName.toLowerCase().includes(currValue.toLowerCase())
              ) {
                return entry;
              }
              return null;
            });
            setSearchData(filteredData);
          }}
          disabled={!rfqRow || rfqRow.length === 0}
        />
      </Text>

      <Table
        columns={actionColumn}
        dataSource={searchData.length > 0 ? searchData : rfqRow}
        pagination={{ pageSize: 5 }}
        rowKey="_id"
      />
    </>
  );
};
