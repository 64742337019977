import React, { useState, useEffect } from "react";
import {
  getordered,
  verified,
  paymentVerify,
  getNote,
  viewNotes,
} from "../../api/requestForQuotation.api";
import { FileAddOutlined } from "@ant-design/icons";

//import { returnedOrder } from "../../api/order.api";
import {
  Table,
  Button,
  Tag,
  Modal,
  Row,
  Col,
  Typography,
  Space,
  Image,
  Input,
  Tooltip,
} from "antd";
import Swal from "sweetalert2";
import {
  capitalizeFirstLetter,
  formatDate,
  formatDateWithTime,
} from "../../helpers/utils";
import DefaultLoader from "../Shared/loader";

const { Text, Title } = Typography;

export const PaymentData = () => {
  const [viewData, setViewData] = useState({});
  const [openView, setOpenView] = React.useState(false);
  const [openQuotation, setOpenQuotation] = React.useState(false);
  const [rfqRow, setRfqRow] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderData, setOrderData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleView = async (data) => {
    setViewData(data);
    setOpenView(true);
  };

  //search
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]); // This is the data that will be filtered

  const handleOrder = async (id) => {
    const response = await paymentVerify(id);
    if (response.status === 200) {
      setOrderData(response.data);
      setOpenQuotation(true);
    }
  };

  const handleDelete = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "deleteButton1",
          cancelButton: "veiwButton1",
        },
        buttonsStyling: false,
      });

      const result = await swalWithBootstrapButtons.fire({
        title: "Are you sure?",
        text: "You want to verify the payment?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        // Make the axios delete request
        const response = await verified(id);
        if (response.status === 200) {
          swalWithBootstrapButtons.fire("Done!", "Payment Verified", "success");
          // Update the table
          const newRfqRow = rfqRow.filter((row) => row._id !== id);
          setRfqRow(newRfqRow);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire("Cancelled", "Done", "error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  //view the user of the RfQ
  const [notesData, setNoteViewData] = useState({});

  const handleNotesView = async (id) => {
    const response = await viewNotes(id);
    if (response.status === 200) {
      setNoteViewData(response.data);
    }
  };

  //handle the add shipping HEE HEE
  const [notes, setNotes] = useState("");
  const [notedata, setNotesData] = useState({});
  const [openNotes, setOpenNotes] = React.useState(false);

  const handleNotes = async (data) => {
    setNotesData(data);
    setNotes(data.notes);

    setOpenNotes(true);
  };

  const resetNotes = () => {
    setNotes("");
  };

  const handleNotesSubmit = async () => {
    const params = {
      rfqId: notedata,
      notes: notes,
    };

    setLoading(true);
    try {
      const response = await getNote(params);
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Note Added Successfully",
        });
        resetNotes();
        handleNotesView(notedata);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const actionColumn = [
    { dataIndex: "_id", title: "ID", width: 210, key: "_id" },
    {
      dataIndex: "rfq",
      title: "Image",
      width: 100,
      key: "rfq",
      render: (_, params) => {
        return (
          <Image
            src={params.image}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://antswelead.sgp1.digitaloceanspaces.com/internal_files/335623101_583997373674210_5297548643792796750_n.jpg";
            }}
            alt="img"
            style={{ width: "80px", height: "60px", objectFit: "cover" }}
          />
        );
      },
    },
    {
      dataIndex: "prodName",
      title: "PRODUCT NAME",
      width: 170,
      key: "prodName",
    },
    { dataIndex: "targetqty", title: "QUANTITY", width: 100, key: "targetqty" },
    {
      dataIndex: "deadline",
      title: "DEADLINE",
      width: 170,
      key: "deadline",
      render(_, params) {
        return (
          <div>
            <p>{formatDate(params.deadline)}</p>
          </div>
        );
      },
    },

    {
      dataIndex: "status",
      title: "Status",
      width: 130,
      key: "_id",
      render: (_, params) => {
        return (
          <Tag color={params.status === "pending" ? "magenta" : "green"}>
            {capitalizeFirstLetter(params.status)}
          </Tag>
        );
      },
    },

    {
      dataIndex: "orderId",
      title: "",
      width: 0,
      key: "orderId",
      render: () => null,
    },

    {
      dataIndex: "createdAt",
      title: "CREATED AT",
      width: 170,
      key: "createdAt",
      sorter: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      defaultSortOrder: "ascend",
      render(_, params) {
        return (
          <div>
            <p>{formatDate(params.createdAt)}</p>
          </div>
        );
      },
    },

    {
      dataIndex: "action",
      title: "Action",
      width: 230,
      key: "_id",
      render: (_, params) => {
        return (
          <Space direction="vertical">
            <Button
              style={{ width: "120.73px" }}
              size="small"
              type="primary"
              onClick={() => {
                handleView(params);
              }}
            >
              View
            </Button>
            <Button
              style={{ width: "120.73px" }}
              size="small"
              type="dashed"
              onClick={() => {
                handleOrder(params._id);
              }}
            >
              Proof of Payment
            </Button>
            <Button
              style={{ width: "120.73px" }}
              size="small"
              type="default"
              onClick={() => {
                handleDelete(params._id, params.prodName);
              }}
              success
            >
              Verify
            </Button>
          </Space>
        );
      },
    },
    {
      dataIndex: "notes",
      title: "Notes",
      width: 230,
      key: "_id",
      render: (_, params) => {
        return (
          <Space
            direction="vertical"
            align="center"
            style={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip title="Add Notes">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleNotes(params._id);
                  handleNotesView(params._id);
                }}
              >
                <FileAddOutlined style={{ fontSize: "50px" }} />
              </div>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getordered();
        const data = await response.data;
        setRfqRow(data.Requests);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return <DefaultLoader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Space direction="vertical" style={{ padding: "15px" }}>
      <Title variant="h3">Payed Quotations</Title>
      {/* Another modal for viewing all details of the selected data */}
      <Modal
        open={openView}
        onCancel={() => {
          setOpenView(false);
        }}
        footer={null}
        title={"View Details"}
        width={800}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Image src={viewData?.image} alt="img" width={300} />
          </Col>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Product Name</Text>
                <Text>{viewData?.prodName}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Target Quantity</Text>
                <Text>{viewData?.targetqty}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Deadline</Text>
                <Text>{viewData?.deadline}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Status</Text>
                <Text>{viewData?.status}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Description</Text>
                <Text>{viewData?.description}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Specifications</Text>
                <Text>{viewData?.specs}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Files</Text>
                <a href={viewData?.files} target="_blank" rel="noreferrer">
                  {viewData?.files?.split("/").pop()}
                </a>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>

      <Modal
        open={openQuotation}
        onCancel={() => {
          setOpenQuotation(false);
          setOrderData();
        }}
        title={"Proof of Payment"}
        width={800}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenQuotation(false);
              setOrderData();
            }}
          >
            Back
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Image src={orderData?.image} alt="img" width={300} />
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Transaction nNumber</Text>
                <Text>{orderData?.TransactionNumber}</Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>

      {/* modal of notes */}

      <Modal
        open={openNotes}
        onCancel={() => {
          setOpenNotes(false);
          resetNotes();
        }}
        title={"Set Notes"}
        width={1000}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenNotes(false);
            }}
          >
            Cancel
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>
                  Product Name : {notesData?.rfqData?.prodName}
                </Text>
                <Text>
                  {/* gets the no note */}
                  <ul>
                    {notesData.total > 0 ? (
                      //getting the note
                      <ul
                        style={{
                          backgroundColor: "#FAFAFA",
                          width: "600px",
                          borderRadius: "10px",
                          padding: "15px",
                          listStyle: "none",
                          maxHeight: "400px",
                          overflow: "auto",
                        }}
                      >
                        {notesData.notes
                          .sort(
                            (a, b) =>
                              new Date(b.createdAt) - new Date(a.createdAt)
                          )
                          .map((note) => (
                            <li
                              key={note._id}
                              style={{
                                backgroundColor: "white",
                                borderRadius: "10px",
                                padding: "5px",
                                margin: "2px",
                                border: "#ECECEC solid 1px",
                              }}
                            >
                              <span
                                style={{
                                  color: "green",
                                  fontSize: "11px",
                                  paddingRight: "20px",
                                }}
                              >
                                {formatDateWithTime(note.createdAt)}:
                              </span>
                              {note.notes}{" "}
                            </li>
                          ))}
                      </ul>
                    ) : (
                      <Text>No notes</Text>
                    )}
                  </ul>
                </Text>
              </Space>
            </Space>
          </Col>

          <Col span={12} push={3}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Add Notes</Text>
                <Input.TextArea
                  style={{ width: "350px", height: "250px" }}
                  value={notes}
                  onChange={(newnotes) => {
                    setNotes(newnotes.target.value);
                  }}
                ></Input.TextArea>

                <Button
                  style={{
                    width: "350px",
                    fontSize: "20px",
                    height: "40px",
                    backgroundColor: "#82CD47",
                  }}
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={handleNotesSubmit}
                >
                  Submit
                </Button>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>

      {/* search */}
      <Text>
        Search:
        <Input
          style={{ margin: "20px", width: "300px" }}
          placeholder="Search Product Name"
          value={searchValue}
          onChange={(e) => {
            const currValue = e.target.value;
            setSearchValue(currValue);
            const filteredData = rfqRow.filter((entry) => {
              if (
                entry.prodName.toLowerCase().includes(currValue.toLowerCase())
              ) {
                return entry;
              }
              return null;
            });
            setSearchData(filteredData);
          }}
          disabled={!rfqRow || rfqRow.length === 0}
        />
      </Text>

      <Table
        columns={actionColumn}
        dataSource={searchData.length > 0 ? searchData : rfqRow}
        pagination={{ pageSize: 5 }}
        rowKey="_id"
      />
    </Space>
  );
};
