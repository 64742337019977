import { create } from "zustand";

const productSearchStore = create((set) => ({
  searchedProducts: [],
  selectedProduct: null,
  setProducts: (searchedProducts) => set({ searchedProducts }),
  //   add single product
  addProduct: (product) =>
    set((state) => ({
      searchedProducts: [...state.searchedProducts, product],
    })),
  removeProducts: () => set({ searchedProducts: [] }),
  setSelectedProduct: (product) => set({ selectedProduct: product }),
}));

export default productSearchStore;
