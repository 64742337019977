import axios from "../helpers/axios";

export const getTracking = async () => {
  return axios
    .get("/api/tracking_link/")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const Activate = async (id) => {
  return axios
    .post("/api/tracking_link/set-tracking-active", { id })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const AddTracking = async (tracking_link) => {
  return axios
    .post("/api/tracking_link/create-tracking", { tracking_link })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const ManualTrackingAdd = async (
  rfq_id,
  status,
  title,
  description,
  date
) => {
  return axios
    .post("/api/check_shipping/add-manual-shipping", {
      rfq_id,
      status,
      title,
      description,
      date,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getManualTracking = async (rfq_id) => {
  return axios
    .post("/api/check_shipping/check-manual-shipping", { rfq_id })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getAll = async () => {
  return axios
    .get("/api/check_shipping/get-all")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deletedata = async (id) => {
  return axios
    .delete(`/api/check_shipping/delete?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
