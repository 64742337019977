import React, { useState } from "react";

import { PendingData } from "../../components/datatable/rfqpendingData";
import { OrderData } from "../../components/datatable/rfqorderedData";
import { VerifiedData } from "../../components/datatable/rfqverifiedData";
import { ShippedData } from "../../components/datatable/rfqshippedData";
import { DeliveredData } from "../../components/datatable/rfqdeliveredData";
import { QuotedData } from "../../components/datatable/quotedData";
import { AcceptedData } from "../../components/datatable/rfqacceptedData";
import { Tabs, Divider, Typography } from "antd";

const { Title } = Typography;
const { TabPane } = Tabs;

const RfqManagement = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [activeStatus, setActiveStatus] = useState("Pending");

  const statuses = [
    "Pending",
    "Quoted",
    "Accepted",
    "Ordered",
    "Verified",
    "Shipped",
    "Delivered",
  ];

  const getStatusContent = (status) => {
    switch (status) {
      case "Pending":
        return <PendingData key={activeTab} />;
      case "Quoted":
        return <QuotedData key={activeTab} />;
      case "Accepted":
        return <AcceptedData key={activeTab} />;
      case "Ordered":
        return <OrderData key={activeTab} />;
      case "Verified":
        return <VerifiedData key={activeTab} />;
      case "Shipped":
        return <ShippedData key={activeTab} />;
      case "Delivered":
        return <DeliveredData key={activeTab} />;
      default:
        return null;
    }
  };

  const handleTabChange = (key) => {
    setActiveTab(key);

    switch (key) {
      case "1":
        setActiveStatus("Pending Quotations");
        break;
      case "2":
        setActiveStatus("Quoted Quotations");
        break;
      case "3":
        setActiveStatus("Accepted Quotations");
        break;
      case "4":
        setActiveStatus("Ordered Quotations");
        break;
      case "5":
        setActiveStatus("Verified Quotations");
        break;
      case "6":
        setActiveStatus("Shipped Quotations");
        break;
      case "7":
        setActiveStatus("Delivered Quotations");
        break;
      default:
        return null;
    }
  };

  return (
    <>
      <Divider orientation="left">
        {" "}
        <Title variant="h5">List of {activeStatus}</Title>
      </Divider>

      <Tabs
        tabBarGutter={70}
        type="card"
        defaultActiveKey={activeTab}
        centered
        onChange={handleTabChange}
      >
        {statuses.map((status, index) => (
          <TabPane
            tab={status}
            key={String(index + 1)}
            style={{ padding: "15px" }}
          >
            {getStatusContent(status)}
          </TabPane>
        ))}
      </Tabs>
    </>
  );
};

export default RfqManagement;
