import React, { useState, useEffect } from "react";
import "./loading.scss";

import { getnotvipUser, vip } from "../../api/user.api";
import { Table, Button, Space, Tag } from "antd";
import Swal from "sweetalert2";
import DefaultLoader from "../Shared/loader";
//import { capitalizeFirstLetter} from "../../helpers/utils";

//import { formatDate } from "../../helpers/utils";

export const NotVipUsers = () => {
  const [userRow, setUserRow] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleVerify = async (id, firstName, lastName) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "deleteButton1",
          cancelButton: "veiwButton1",
        },
        // buttonsStyling: false,
      });

      const result = await swalWithBootstrapButtons.fire({
        title: "Are you sure?",
        text: "You want to set VIP " + firstName + " " + lastName + "?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        // Make the axios delete request
        const response = await vip(id);
        if (response.status === 200) {
          swalWithBootstrapButtons.fire(
            "Done!",
            "User is now VIP!.",
            "success"
          );
          // Update the table
          const newUserRow = userRow.filter((row) => row._id !== id);
          setUserRow(newUserRow);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire("Cancelled", "Done", "error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const actionColumn = [
    {
      dataIndex: "email",
      title: "Email",
      key: "email",
    },
    {
      dataIndex: "firstName",
      title: "First name",
      key: "firstName",
    },
    { dataIndex: "lastName", title: "Last Name", width: 170, key: "lastName" },
    {
      dataIndex: "isVIP",
      title: "VIP",
      key: "_id",
      render: (_, params) => {
        return (
          <Tag color={params.isVIP === false ? "magenta" : "green"}>
            {params.isVIP.toString()}
          </Tag>
        );
      },
    },

    {
      dataIndex: "action",
      title: "Action",
      key: "_id",
      render: (_, params) => {
        return (
          <Space direction="horizontal">
            <Button
              type="primary"
              onClick={() => {
                handleVerify(params._id, params.firstName, params.lastName);
              }}
              success
            >
              set VIP
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getnotvipUser();
        const data = await response.data;
        setUserRow(data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return <DefaultLoader />;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      {/* Another modal for viewing all details of the selected data */}
      <Table
        columns={actionColumn}
        dataSource={userRow}
        pagination={{ pageSize: 5 }}
        rowKey="_id"
      />
    </>
  );
};
