import React, { useState, useEffect } from "react";
import { getVip } from "../../api/user.api";
// import { FileAddOutlined } from "@ant-design/icons";

//import { returnedOrder } from "../../api/order.api";
import {
  Table,
  Button,
  Tag,
  Modal,
  Row,
  Col,
  Typography,
  Space,
  Image,
  Input,
  // Tooltip,
} from "antd";
// import Swal from "sweetalert2";
import {
  capitalizeFirstLetter,
  formatDate,
  formatDateWithTime,
} from "../../helpers/utils";
import DefaultLoader from "../Shared/loader";

const { Text, Title } = Typography;

export const VipPayment = () => {
  const [viewData, setViewData] = useState({});
  const [openView, setOpenView] = React.useState(false);
  // const [openQuotation, setOpenQuotation] = React.useState(false);
  const [vipRow, setVipRow] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [orderData, setOrderData] = useState({});
  // const [loading, setLoading] = useState(false);

  const handleView = async (data) => {
    setViewData(data);
    setOpenView(true);
  };

  const [openProof, setOpenProof] = React.useState(false);
  const handleProof = async (data) => {
    setViewData(data);
    setOpenProof(true);
  };

  //search
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]); // This is the data that will be filtered

  // const handleOrder = async (id) => {
  //   const response = await paymentVerify(id);
  //   if (response.status === 200) {
  //     setOrderData(response.data);
  //     setOpenQuotation(true);
  //   }
  // };

  // const handleDelete = async (id) => {
  //   try {
  //     const swalWithBootstrapButtons = Swal.mixin({
  //       customClass: {
  //         confirmButton: "deleteButton1",
  //         cancelButton: "veiwButton1",
  //       },
  //       buttonsStyling: false,
  //     });

  //     const result = await swalWithBootstrapButtons.fire({
  //       title: "Are you sure?",
  //       text: "You want to verify the payment?",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes",
  //       cancelButtonText: "Cancel",
  //       reverseButtons: true,
  //     });

  //     if (result.isConfirmed) {
  //       // Make the axios delete request
  //       const response = await verified(id);
  //       if (response.status === 200) {
  //         swalWithBootstrapButtons.fire("Done!", "Payment Verified", "success");
  //         // Update the table
  //         const newRfqRow = rfqRow.filter((row) => row._id !== id);
  //         setRfqRow(newRfqRow);
  //       }
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       swalWithBootstrapButtons.fire("Cancelled", "Done", "error");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // //view the user of the RfQ
  // const [notesData, setNoteViewData] = useState({});

  // const handleNotesView = async (id) => {
  //   const response = await viewNotes(id);
  //   if (response.status === 200) {
  //     setNoteViewData(response.data);
  //   }
  // };

  // //handle the add shipping HEE HEE
  // const [notes, setNotes] = useState("");
  // const [notedata, setNotesData] = useState({});
  // const [openNotes, setOpenNotes] = React.useState(false);

  // const handleNotes = async (data) => {
  //   setNotesData(data);
  //   setNotes(data.notes);

  //   setOpenNotes(true);
  // };

  // const resetNotes = () => {
  //   setNotes("");
  // };

  // const handleNotesSubmit = async () => {
  //   const params = {
  //     rfqId: notedata,
  //     notes: notes,
  //   };

  //   setLoading(true);
  //   try {
  //     const response = await getNote(params);
  //     if (response.status === 200) {
  //       Swal.fire({
  //         icon: "success",
  //         title: "Success",
  //         text: "Note Added Successfully",
  //       });
  //       resetNotes();
  //       handleNotesView(notedata);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const actionColumn = [
    { dataIndex: "_id", title: "ID", width: 210, key: "_id" },
    {
      dataIndex: "email",
      title: "EMAIL",
      width: 170,
      key: "email",
    },
    {
      dataIndex: "status",
      title: "Status",
      width: 130,
      key: "_id",
      render: (_, params) => {
        return (
          <Tag color={params.status === "pending" ? "magenta" : "green"}>
            {capitalizeFirstLetter(params.status)}
          </Tag>
        );
      },
    },
    {
      dataIndex: "createdAt",
      title: "CREATED AT",
      width: 170,
      key: "createdAt",
      sorter: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      defaultSortOrder: "ascend",
      render(_, params) {
        return (
          <div>
            <p>{formatDate(params.createdAt)}</p>
          </div>
        );
      },
    },

    {
      dataIndex: "action",
      title: "Action",
      width: 230,
      key: "_id",
      render: (_, params) => {
        const isPaid = params.status === "payed";
        return (
          <Space direction="vertical">
            <Button
              style={{ width: "120.73px" }}
              size="small"
              type="primary"
              onClick={() => {
                handleView(params);
              }}
            >
              View
            </Button>
            <Button
              style={{ width: "120.73px" }}
              size="small"
              type="dashed"
              onClick={() => {
                handleProof(params);
              }}
              disabled={!isPaid}
            >
              Proof of Payment
            </Button>
            {/* <Button
              style={{ width: "120.73px" }}
              size="small"
              type="default"
              onClick={() => {
                handleDelete(params._id, params.prodName);
              }}
              success
            >
              Verify
            </Button> */}
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getVip();
        const data = await response.data;
        setVipRow(data.rows);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return <DefaultLoader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Space direction="vertical" style={{ padding: "15px" }}>
      <Title variant="h3">Request for VIP</Title>
      {/* Another modal for viewing all details of the selected data */}
      <Modal
        open={openView}
        onCancel={() => {
          setOpenView(false);
        }}
        footer={null}
        title={"View Details"}
        width={800}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>User Id</Text>
                <Text>{viewData?.userId}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Email</Text>
                <Text>{viewData?.email}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Status</Text>
                <Text>{viewData?.status}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Created At</Text>
                <Text> {formatDateWithTime(viewData.createdAt)}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Updated At</Text>
                <Text> {formatDateWithTime(viewData.updatedAt)}</Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>

      <Modal
        open={openProof}
        onCancel={() => {
          setOpenProof(false);
        }}
        footer={null}
        title={"View Details"}
        width={800}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Col span={12}>
                <Space direction="vertical">
                  <Image src={viewData?.image} alt="img" width={300} />
                </Space>
              </Col>

              <Space direction="vertical">
                <Text strong>Email</Text>
                <Text>{viewData?.email}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Transaction-ID</Text>
                <Text>{viewData?.transactionNumber}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Updated At</Text>
                <Text> {formatDateWithTime(viewData.updatedAt)}</Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>

      {/* <Modal
        open={openQuotation}
        onCancel={() => {
          setOpenQuotation(false);
          setOrderData();
        }}
        title={"Proof of Payment"}
        width={800}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenQuotation(false);
              setOrderData();
            }}
          >
            Back
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Image src={orderData?.image} alt="img" width={300} />
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Transaction nNumber</Text>
                <Text>{orderData?.TransactionNumber}</Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal> */}

      {/* modal of notes */}

      {/* <Modal
        open={openNotes}
        onCancel={() => {
          setOpenNotes(false);
          resetNotes();
        }}
        title={"Set Notes"}
        width={1000}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenNotes(false);
            }}
          >
            Cancel
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>
                  Product Name : {notesData?.rfqData?.prodName}
                </Text>
                <Text>
                  <ul>
                    {notesData.total > 0 ? (
                      //getting the note
                      <ul
                        style={{
                          backgroundColor: "#FAFAFA",
                          width: "600px",
                          borderRadius: "10px",
                          padding: "15px",
                          listStyle: "none",
                          maxHeight: "400px",
                          overflow: "auto",
                        }}
                      >
                        {notesData.notes
                          .sort(
                            (a, b) =>
                              new Date(b.createdAt) - new Date(a.createdAt)
                          )
                          .map((note) => (
                            <li
                              key={note._id}
                              style={{
                                backgroundColor: "white",
                                borderRadius: "10px",
                                padding: "5px",
                                margin: "2px",
                                border: "#ECECEC solid 1px",
                              }}
                            >
                              <span
                                style={{
                                  color: "green",
                                  fontSize: "11px",
                                  paddingRight: "20px",
                                }}
                              >
                                {formatDateWithTime(note.createdAt)}:
                              </span>
                              {note.notes}{" "}
                            </li>
                          ))}
                      </ul>
                    ) : (
                      <Text>No notes</Text>
                    )}
                  </ul>
                </Text>
              </Space>
            </Space>
          </Col>

          <Col span={12} push={3}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Add Notes</Text>
                <Input.TextArea
                  style={{ width: "350px", height: "250px" }}
                  value={notes}
                  onChange={(newnotes) => {
                    setNotes(newnotes.target.value);
                  }}
                ></Input.TextArea>
                <Button
                  style={{
                    width: "350px",
                    fontSize: "20px",
                    height: "40px",
                    backgroundColor: "#82CD47",
                  }}
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={handleNotesSubmit}
                >
                  Submit
                </Button>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal> */}

      {/* search */}
      <Text>
        Search:
        <Input
          style={{ margin: "20px", width: "300px" }}
          placeholder="Search Product Name"
          value={searchValue}
          onChange={(e) => {
            const currValue = e.target.value;
            setSearchValue(currValue);
            const filteredData = vipRow.filter((entry) => {
              if (
                entry.prodName.toLowerCase().includes(currValue.toLowerCase())
              ) {
                return entry;
              }
              return null;
            });
            setSearchData(filteredData);
          }}
          disabled={!vipRow || vipRow.length === 0}
        />
      </Text>

      <Table
        columns={actionColumn}
        dataSource={searchData.length > 0 ? searchData : vipRow}
        pagination={{ pageSize: 5 }}
        rowKey="_id"
      />
    </Space>
  );
};
