import { create } from "zustand";

const sellerSearchStore = create((set) => ({
  searchedSellers: [],
  selectedSeller: null,
  setSellers: (searchedSellers) => set({ searchedSellers }),
  //   add single product
  addSeller: (product) =>
    set((state) => ({
      searchedSellers: [...state.searchedSellers, product],
    })),
  removeSellers: () => set({ searchedSellers: [] }),
  setSelectedSeller: (product) => set({ selectedProduct: product }),
}));

export default sellerSearchStore;
