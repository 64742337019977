import React, { useState, useEffect } from "react";
// import dayjs from "dayjs";
import { getBlog } from "../../api/blog.api";
// import { FileAddOutlined } from "@ant-design/icons";
import {
  Table,
  Button,
  // Tag,
  Modal,
  Row,
  Col,
  Typography,
  Space,
  Image,
  Input,
  // InputNumber,
  // DatePicker,
  // Select,
  // Tooltip,
} from "antd";
//import Swal from "sweetalert2";
import // capitalizeFirstLetter,
// formatDate,
// formatDate2,
// formatDateWithTime,
"../../helpers/utils";
import DefaultLoader from "../Shared/loader";

const { Text, Title } = Typography;
// const currencyFormatter = require("currency-formatter");

export const Blogtable = () => {
  const [viewData, setViewData] = useState({});
  // const [quotationData, setQuotationData] = useState({});
  const [openView, setOpenView] = React.useState(false);
  // const [openQuotation, setOpenQuotation] = React.useState(false);

  // //view the user of the RfQ
  // const [userData, setUserData] = useState({});
  // const [openUser, setOpenUser] = React.useState(false);

  const [rfqRow, setRfqRow] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [deadline, setDeadline] = useState(dayjs());
  // const [dateString, setDateString] = useState("");
  // const [price, setPrice] = useState(1.0);
  // const [quantity, setQuantity] = useState(1);
  // const [shipping, setShipping] = useState(1);
  // const [priority, setPriority] = useState("low");
  // const [comment, setComment] = useState("");
  // const [loading, setLoading] = useState(false);

  //search
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]); // This is the data that will be filtered

  const handleView = async (data) => {
    setViewData(data);
    setOpenView(true);
  };

  // address
  // const [address, setAddress] = useState({});

  // const handleAddress = async (addressId) => {
  //   const response = await getrfqAddress(addressId);
  //   if (response.status === 200) {
  //     setAddress(response.data);
  //   }
  // };

  // const handleOrder = async (id) => {
  //   const response = await vieworder(id);
  //   if (response.status === 200) {
  //     setOrderData(response.data);
  //     setOpenMyQuotation(true);
  //   }
  // };

  // const handleQuotation = async (data) => {
  //   setQuotationData(data);
  //   setDeadline(dayjs(data.deadline));
  //   setDateString(formatDate2(data.deadline));
  //   setQuantity(data.targetqty);
  //   setOpenQuotation(true);
  // };

  // const onChange = (date, dateStr) => {
  //   setDeadline(dayjs(dateStr));
  //   setDateString(dateStr);
  // };

  // const resetQuotation = () => {
  //   setPrice(1.0);
  //   setQuantity(1);
  //   setShipping(1);
  //   setComment("");
  //   setDeadline(dayjs());
  // };

  // const handleQuotationSubmit = async () => {
  //   // check price, shipping,deadline
  //   if (price <= 0) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "Price must be greater than 0",
  //     });
  //     return;
  //   }
  //   if (shipping === "") {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "Shipping must not be empty",
  //     });
  //     return;
  //   }
  //   if (dateString === "") {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "Deadline must not be empty",
  //     });
  //     return;
  //   }

  //   const params = {
  //     delivery_address_id:
  //       quotationData.deliveryAddressId || "No Delivery Address",
  //     rfqId: quotationData._id,
  //     price: price,
  //     shipping: shipping,
  //     comment: comment,
  //     deadline: dateString, // using yyyy-mm-dd format
  //     image: quotationData.image,
  //     priority: priority,
  //     prod_name: quotationData.prodName,
  //     quantityavailable: quantity,
  //   };
  //   setLoading(true);
  //   try {
  //     const response = await returnedOrder(params);
  //     if (response.status === 200) {
  //       Swal.fire({
  //         icon: "success",
  //         title: "Success",
  //         text: "Quotation created successfully",
  //       });
  //       setOpenQuotation(false);
  //       resetQuotation();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleDelete = async (id, name) => {
  //   try {
  //     const swalWithBootstrapButtons = Swal.mixin({
  //       customClass: {
  //         confirmButton: "deleteButton1",
  //         cancelButton: "veiwButton1",
  //       },
  //       buttonsStyling: false,
  //     });

  //     const result = await swalWithBootstrapButtons.fire({
  //       title: "Are you sure?",
  //       text: "You want to delete " + name + "?",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonText: "Delete",
  //       cancelButtonText: "Cancel",
  //       reverseButtons: true,
  //     });

  //     if (result.isConfirmed) {
  //       // Make the axios delete request
  //       const response = await deleteRfQ(id);
  //       if (response.status === 200) {
  //         swalWithBootstrapButtons.fire(
  //           "Deleted!",
  //           "Your file has been deleted.",
  //           "success"
  //         );
  //         // Update the table
  //         const newRfqRow = rfqRow.filter((row) => row._id !== id);
  //         setRfqRow(newRfqRow);
  //       }
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       swalWithBootstrapButtons.fire("Cancelled", "Done", "error");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const actionColumn = [
    {
      dataIndex: "title",
      title: "Title",
      width: 170,
      key: "title",
    },

    {
      dataIndex: "content",
      title: "Content",
      width: 800,
      key: "content",
    },

    {
      dataIndex: "action",
      title: "Action",
      width: 150,
      key: "_id",
      render: (_, params) => {
        return (
          <Space direction="vertical" align="center">
            <Button
              style={{ background: "green", width: "120.73px" }}
              size="small"
              type="primary"
              onClick={() => {
                handleView(params);
              }}
            >
              View
            </Button>
            {/* 
            <Button
              style={{ background: "Blue", width: "120.73px" }}
              size="small"
              type="primary"
              onClick={() => {
                handleUser(params._id);
              }}
            >
              View Customer
            </Button> */}
            {/* <Button
              style={{ width: "120.73px" }}
              size="small"
              type="primary"
              onClick={() => {
                handleDelete(params._id, params.prodName);
              }}
              danger
            >
              Delete
            </Button> */}
            {/* 
            {params.orderId === null && params.status === "pending" && (
              <Button
                style={{ width: "120.73px" }}
                size="small"
                type="dashed"
                onClick={() => {
                  handleQuotation(params);
                }}
              >
                Quotation
              </Button>
            )}

            {params.orderId !== null && (
              <Button
                style={{ width: "120.73px" }}
                size="small"
                type="dashed"
                onClick={() => {
                  handleOrder(params.orderId);
                }}
              >
                See Quotation
              </Button>
            )} */}
          </Space>
        );
      },
    },
    // {
    //   dataIndex: "notes",
    //   title: "Notes",
    //   width: 230,
    //   key: "_id",
    //   render: (_, params) => {
    //     return (
    //       <Space
    //         direction="vertical"
    //         align="center"
    //         style={{
    //           height: "100%",
    //           justifyContent: "center",
    //           alignItems: "center",
    //         }}
    //       >
    //         <Tooltip title="Add Notes">
    //           <div
    //             style={{ cursor: "pointer" }}
    //             onClick={() => {
    //               handleNotes(params._id);
    //               handleNotesView(params._id);
    //             }}
    //           >
    //             <FileAddOutlined style={{ fontSize: "50px" }} />
    //           </div>
    //         </Tooltip>
    //       </Space>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getBlog();
        const data = await response.data;
        // add total field from data.estimatedPrice * data.targetqty
        const newData = data.rows.map((row) => {
          return {
            ...row,
            total: row.estimatedPrice * row.targetqty,
          };
        });
        setRfqRow(newData);
        // setRfqRow(data.rows);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    // Center div
    return <DefaultLoader></DefaultLoader>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Space direction="vertical" style={{ padding: "15px" }}>
      <Title variant="h3">Content's Table</Title>
      {/* Another modal for viewing all details of the selected data */}
      <Modal
        open={openView}
        onCancel={() => {
          setOpenView(false);
        }}
        footer={null}
        title={"View Details"}
        width={800}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Title</Text>
                <Text>{viewData?.title}</Text>
              </Space>
            </Space>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Content</Text>
                <Text>{viewData?.content}</Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>

      {/* <Modal
        open={openQuotation}
        onCancel={() => {
          setOpenQuotation(false);
          resetQuotation();
        }}
        title={"Set Quotation"}
        width={800}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenQuotation(false);
              resetQuotation();
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleQuotationSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Image src={quotationData?.image} alt="img" width={300} />
              <Space direction="vertical">
                <Text strong>Product Name</Text>
                <Text>{capitalizeFirstLetter(quotationData?.prodName)}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Target Quantity</Text>
                <Text>{quotationData?.targetqty}</Text>
              </Space>

              <Space direction="vertical">
                <Text strong>Status</Text>
                <Text>{capitalizeFirstLetter(quotationData?.status)}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Description</Text>
                <Text>{quotationData?.description}</Text>
              </Space>
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Specifications</Text>
                <Text>{quotationData?.specs}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Files</Text>
                <a href={quotationData?.files} target="_blank" rel="noreferrer">
                  {quotationData?.files?.split("/").pop()}
                </a>
              </Space>
              <Space direction="vertical">
                <Text strong>Deadline</Text>
                {openQuotation ? (
                  <DatePicker
                    onChange={onChange}
                    defaultValue={deadline}
                    value={deadline}
                  />
                ) : null}
              </Space>
              <Space direction="vertical">
                <Text strong>Set Price Per Piece</Text>
                <InputNumber
                  step="0.01"
                  value={price}
                  onChange={(newPrice) => {
                    setPrice(newPrice);
                  }}
                ></InputNumber>
              </Space>
              <Space direction="vertical">
                <Text strong>Quantity</Text>
                <InputNumber
                  value={quantity}
                  onChange={(newQuantity) => {
                    setQuantity(newQuantity);
                  }}
                ></InputNumber>
              </Space>
              <Space direction="vertical">
                <Text strong>Shipping Fee</Text>
                <InputNumber
                  step="0.01"
                  value={shipping}
                  onChange={(newQuantity) => {
                    setShipping(newQuantity);
                  }}
                ></InputNumber>
              </Space>
              <Space direction="vertical">
                <Text strong>Priority</Text>
                <Select
                  defaultValue="low"
                  style={{ width: 120 }}
                  onChange={(value) => {
                    setPriority(value);
                  }}
                  options={[
                    { value: "low", label: "Low" },
                    { value: "medium", label: "Medium" },
                    { value: "high", label: "High" },
                  ]}
                />
              </Space>
              <Space direction="vertical">
                <Text strong>Comments</Text>
                <Input.TextArea
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                ></Input.TextArea>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal> */}

      <Text>
        Search:
        <Input
          style={{ margin: "20px", width: "300px" }}
          placeholder="Search"
          value={searchValue}
          onChange={(e) => {
            const currValue = e.target.value;
            setSearchValue(currValue);
            const filteredData = rfqRow.filter((entry) => {
              if (
                entry.prodName
                  .toLowerCase()
                  .includes(currValue.toLowerCase()) ||
                entry.status.toLowerCase().includes(currValue.toLowerCase())
              ) {
                return entry;
              }
              return null;
            });
            setSearchData(filteredData);
          }}
          disabled={!rfqRow || rfqRow.length === 0}
        />
      </Text>
      <Button
        style={{ float: "right", marginBottom: "30px" }}
        type="primary"
        onClick={() => {
          //setOpenCreate(true);
        }}
      >
        Create Post
      </Button>

      <Table
        columns={actionColumn}
        dataSource={searchData.length > 0 ? searchData : rfqRow}
        pagination={{ pageSize: 5 }}
        rowKey="_id"
      />
    </Space>
  );
};
