import axios from "../helpers/axios";

export const loginUser = async (email, password) => {
  return axios
    .post("/api/auth/admin-login", { email, password })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const registerUser = async (name, email, password) => {
  return axios
    .post("/api/auth/register", { name, email, password })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
