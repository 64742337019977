import moment from "moment";
// Function that capitalizes the first letter of a string
export const capitalizeFirstLetter = (string) => {
  if (string) {
    if (typeof string !== "string") {
      throw new Error("The string argument is not a string");
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
};

// function that formats 2023-04-07T00:00:00.000Z to April 7, 2023
export const formatDate = (date) => {
  const newDate = new Date(date);
  return newDate.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

// function that formats 2023-04-07T00:00:00.000Z to month-day-year format using moment
export const formatDate2 = (date) => {
  return moment(date).format("yyyy-MM-DD");
};

export const formatDateWithTime = (date) => {
  const newDate = new Date(date);
  return newDate.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};
