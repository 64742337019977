import axios from "../helpers/axios";

// export const createInvoice = async (params) => {
//     return axios
//       .post("/api/invoice/insert-invoice", params)
//       .then((res) => {
//         return res;
//       })
//       .catch((err) => {
//         return err.response;
//       });
//   };

export const getInvoice = async () => {
  return axios
    .get("api/invoice/")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const viewRFQ = async (id) => {
  return axios
    .post("/api/invoice/view-invoice", { id })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
