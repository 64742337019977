import axios from "../helpers/axios";

export const getRfq = async () => {
  return axios
    .get("/api/requestForQuotation/get-all-data")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getRfqby5 = async () => {
  return axios
    .get("/api/requestForQuotation/?pageSize=5")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteRfQ = async (id) => {
  return axios
    .delete(`/api/requestForQuotation/delete?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const viewRFQ = async (id) => {
  return axios
    .post("/api/requestForQuotation/get-rfq-by-id", { id })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const userRFQ = async (id) => {
  return axios
    .post("/api/requestForQuotation/get-user-rfq-by-id", { id })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const createQuotation = async (params) => {
  return axios
    .post("/api/requestForQuotation/request-for-quotation", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getpending = async () => {
  return axios
    .get("/api/requestForQuotation/get-all-pending-rfq")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getordered = async () => {
  return axios
    .get("/api/requestForQuotation/get-all-ordered-rfq")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getAccepted = async () => {
  return axios
    .get("/api/requestForQuotation/get-all-accepted-rfq")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getverified = async () => {
  return axios
    .get("/api/requestForQuotation/get-all-verified-rfq")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getshipped = async () => {
  return axios
    .get("/api/requestForQuotation/get-all-shipped-rfq")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getdelivered = async () => {
  return axios
    .get("/api/requestForQuotation/get-all-delivered-rfq")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const vieworder = async (id) => {
  return axios
    .post("/api/order/get-order-details", { id })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const verified = async (rfqId) => {
  return axios
    .post("/api/requestForQuotation/verify-payment", { rfqId })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const paymentVerify = async (id) => {
  return axios
    .post("/api/payment/get-payment-rfq", { id })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const Shipping = async (rfqId) => {
  return axios
    .post("/api/requestForQuotation/to-be-shipped", { rfqId })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getRfqTotal = async () => {
  return axios
    .get("/api/requestForQuotation/getrfqTotals")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getQuoted = async () => {
  return axios
    .get("/api/requestForQuotation/get-all-pending-quoted")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getDeclined = async () => {
  return axios
    .get("/api/requestForQuotation/get-all-rejected-rfq")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const createInvoice = async (params) => {
  return axios
    .post("/api/invoice/insert-invoice", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getrfqAddress = async (addressId) => {
  return axios
    .post("/api/address/get-address", { addressId })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

//shipping

export const getShipped = async (params) => {
  return axios
    .post("/api/shipping/add-shipping", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getNote = async (params) => {
  return axios
    .post("/api/notes/insert-note", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const viewNotes = async (id) => {
  return axios
    .post("/api/notes/get-rfq-notes", { id })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateShipped = async (params) => {
  return axios
    .post("/api/shipping/reshipping", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const viewship = async (id) => {
  return axios
    .post("/api/shipping/get-shipping-rfq", { id })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
