import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  userRFQ,
  vieworder,
  getAccepted,
  getrfqAddress,
  getNote,
  viewNotes,
} from "../../api/requestForQuotation.api";

import {
  FileAddOutlined,
  EyeOutlined,
  UserOutlined,
  SendOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";

import { createInvoice } from "../../api/requestForQuotation.api";
import {
  Table,
  Button,
  Tag,
  Modal,
  Row,
  Col,
  Typography,
  Space,
  Image,
  InputNumber,
  DatePicker,
  Input,
  Select,
  Tooltip,
  Divider,
} from "antd";
import Swal from "sweetalert2";
import {
  capitalizeFirstLetter,
  formatDate,
  formatDate2,
  formatDateWithTime,
} from "../../helpers/utils";
import DefaultLoader from "../Shared/loader";

const { Text } = Typography;

const options = [
  {
    value: 3,
    label: "3% = Level 7",
  },
  {
    value: 5,
    label: "5% = Level 6",
  },
  {
    value: 6,
    label: "6% = Level 5",
  },
  {
    value: 7,
    label: "7% = Level 4",
  },
  {
    value: 8,
    label: "8% = Level 3",
  },
  {
    value: 9,
    label: "9% = Level 2",
  },
  {
    value: 10,
    label: "10% = Level 1",
  },
];

export const AcceptedData = () => {
  const [viewData, setViewData] = useState({});
  const [invoice, setInvoiceData] = useState({});
  const [openView, setOpenView] = React.useState(false);
  const [openInvoice, setOpenInvoice] = React.useState(false);
  const [rfqRow, setRfqRow] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [due, setDue] = useState(dayjs());
  const [dateString, setDateString] = useState("");
  const [tax, setTax] = useState(0);
  const [service, setService] = useState(0);
  const [loading, setLoading] = useState(false);

  const [orderData, setOrderData] = useState({});
  const [openQuotation, setOpenQuotation] = React.useState(false);

  //view the user of the RfQ
  const [userData, setUserData] = useState({});
  const [openUser, setOpenUser] = React.useState(false);

  //address
  const [address, setAddress] = useState({});

  //search
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]); // This is the data that will be filtered

  const handleAddress = async (addressId) => {
    const response = await getrfqAddress(addressId);
    if (response.status === 200) {
      setAddress(response.data);
    }
  };

  const handleView = async (data) => {
    setViewData(data);
    setOpenView(true);
  };

  const handleOrder = async (id) => {
    const response = await vieworder(id);
    if (response.status === 200) {
      setOrderData(response.data);
      setOpenQuotation(true);
    }
  };

  const handleUser = async (id) => {
    const response = await userRFQ(id);
    if (response.status === 200) {
      setUserData(response.data);
      setOpenUser(true);
    }
  };

  const handleInvoice = async (data) => {
    setInvoiceData(data);
    setDue(dayjs(data.due));
    setDateString(formatDate2(data.due));
    setTax(data.tax);
    setService(data.service);
    setOpenInvoice(true);
  };

  const onChange = (due, dateStr) => {
    setDue(dayjs(dateStr));
    setDateString(dateStr);
  };

  const resetInvoice = () => {
    setTax(0);
    setService(0);
    setDue(dayjs());
  };

  const handleInvoiceSubmit = async () => {
    // check price, shipping,deadline
    if (tax < 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Price must be greater than 0",
      });
      return;
    }
    if (service === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Shipping must not be empty",
      });
      return;
    }
    if (dateString === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Deadline must not be empty",
      });
      return;
    }

    const params = {
      rfqId: invoice._id,
      tax: tax,
      service: service,
      due: dateString, // using yyyy-mm-dd format
    };

    setLoading(true);
    try {
      const response = await createInvoice(params);
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Quotation created successfully",
        });
        setOpenInvoice(false);
        resetInvoice();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  //view the user of the RfQ
  const [notesData, setNoteViewData] = useState({});

  const handleNotesView = async (id) => {
    const response = await viewNotes(id);
    if (response.status === 200) {
      setNoteViewData(response.data);
    }
  };

  //handle the add shipping HEE HEE
  const [notes, setNotes] = useState("");
  const [notedata, setNotesData] = useState({});
  const [openNotes, setOpenNotes] = React.useState(false);

  const handleNotes = async (data) => {
    setNotesData(data);
    setNotes(data.notes);

    setOpenNotes(true);
  };

  const resetNotes = () => {
    setNotes("");
  };

  const handleNotesSubmit = async () => {
    const params = {
      rfqId: notedata,
      notes: notes,
    };

    setLoading(true);
    try {
      const response = await getNote(params);
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Note Added Successfully",
        });
        resetNotes();
        handleNotesView(notedata);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const actionColumn = [
    {
      dataIndex: "rfq",
      title: "Image",
      key: "rfq",
      render: (_, params) => {
        return (
          <Image
            src={params.image}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://antswelead.sgp1.digitaloceanspaces.com/internal_files/335623101_583997373674210_5297548643792796750_n.jpg";
            }}
            alt="img"
            style={{ width: "80px", height: "60px", objectFit: "cover" }}
          />
        );
      },
    },
    {
      dataIndex: "prodName",
      title: "PRODUCT NAME",
      key: "prodName",
    },
    { dataIndex: "targetqty", title: "QUANTITY", width: 100, key: "targetqty" },
    {
      dataIndex: "deadline",
      title: "DEADLINE",
      key: "deadline",
      render(_, params) {
        return (
          <div>
            <p>{formatDate(params.deadline)}</p>
          </div>
        );
      },
    },

    {
      dataIndex: "status",
      title: "Status",
      key: "_id",
      render: (_, params) => {
        return (
          <Tag color={params.status === "pending" ? "magenta" : "green"}>
            {capitalizeFirstLetter(params.status)}
          </Tag>
        );
      },
    },

    // import {
    //   FileAddOutlined,
    //   EyeOutlined,
    //   UserOutlined,
    //   DeleteOutlined,
    //   FileDoneOutlined } from "@ant-design/icons";

    {
      dataIndex: "action",
      title: "Action",
      key: "_id",
      render: (_, params) => {
        return (
          <>
            <Button
              size="large"
              shape="circle"
              onClick={() => {
                handleView(params);
                handleAddress(params.deliveryAddressId);
              }}
            >
              <EyeOutlined style={{ color: "green" }} />
            </Button>
            <Divider type="vertical" />
            <Button
              size="large"
              shape="circle"
              onClick={() => {
                handleUser(params._id);
              }}
            >
              <UserOutlined style={{ color: "skyblue" }} />
            </Button>
            <Divider type="vertical" />
            <Button
              size="large"
              shape="circle"
              onClick={() => {
                handleOrder(params.orderId);
              }}
            >
              <FileDoneOutlined style={{ color: "#8B8000" }} />
            </Button>
            <Divider type="vertical" />
            {params.invoiceId === null && (
              <Button
                style={{ backgroundColor: "green" }}
                size="large"
                shape="circle"
                onClick={() => {
                  handleInvoice(params);
                }}
              >
                <SendOutlined style={{ color: "white" }} />
              </Button>
            )}

            {params.invoiceId !== null && (
              <Button
                size="large"
                shape="circle"
                onClick={() => {
                  handleInvoice(params);
                }}
                danger
              >
                <SendOutlined />
              </Button>
            )}
          </>
        );
      },
    },
    {
      dataIndex: "notes",
      title: "Notes",
      key: "_id",
      render: (_, params) => {
        return (
          <>
            <Tooltip title="Add Notes">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleNotes(params._id);
                  handleNotesView(params._id);
                }}
              >
                <FileAddOutlined style={{ fontSize: "50px" }} />
              </div>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAccepted();
        const data = await response.data;
        setRfqRow(data.Requests);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return <DefaultLoader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      {/* Another modal for viewing all details of the selected data */}
      <Modal
        open={openView}
        onCancel={() => {
          setOpenView(false);
        }}
        footer={null}
        title={"View Details"}
        width={800}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Image src={viewData?.image} alt="img" width={300} />

            <Space direction="vertical">
              <Text strong></Text>
              <Text strong>Address</Text>
              {address ? (
                <Text>
                  {address.regionName}, {address.province},{" "}
                  {address.municipalitName}, {address.baranggayName},{" "}
                  {address.street}, {address.zipcode}
                </Text>
              ) : (
                <Text>No address available</Text>
              )}
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Product Name</Text>
                <Text>{viewData?.prodName}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Target Quantity</Text>
                <Text>{viewData?.targetqty}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Deadline</Text>
                <Text>{viewData?.deadline}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Status</Text>
                <Text>{viewData?.status}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Description</Text>
                <Text>{viewData?.description}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Specifications</Text>
                <Text>{viewData?.specs}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Files</Text>
                <a href={viewData?.files} target="_blank" rel="noreferrer">
                  {viewData?.files?.split("/").pop()}
                </a>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>

      <Modal
        open={openInvoice}
        onCancel={() => {
          setOpenInvoice(false);
          resetInvoice();
        }}
        title={"Set Invoice"}
        width={800}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenInvoice(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleInvoiceSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Due Date</Text>
                {openInvoice ? (
                  <DatePicker
                    onChange={onChange}
                    defaultValue={due}
                    value={due}
                  />
                ) : null}
              </Space>
              <Space direction="vertical">
                <Text strong>Tax</Text>
                <InputNumber
                  step="0"
                  value={tax}
                  onChange={(newTax) => {
                    setTax(newTax);
                  }}
                ></InputNumber>
              </Space>
              <Space direction="vertical">
                <Text strong>Commision(%)</Text>
                <Select
                  defaultValue="Select Commission"
                  options={options}
                  value={options.value}
                  onChange={(newService) => {
                    setService(newService);
                  }}
                />
              </Space>
            </Space>
          </Col>
          <Col span={12}>
            <Image
              src="https://antswelead.sgp1.digitaloceanspaces.com/static_video/Screenshot%202023-05-26%20142454.png"
              alt="img"
              width={350}
            />
          </Col>
        </Row>
      </Modal>

      <Modal
        open={openQuotation}
        onCancel={() => {
          setOpenQuotation(false);
          setOrderData();
        }}
        title={"Set Quotation"}
        width={800}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenQuotation(false);
              setOrderData();
            }}
          >
            Back
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Image src={orderData?.image} alt="img" width={300} />
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Delivery Address</Text>
                <Text>{orderData?.delivery_address_id}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Product Name</Text>
                <Text>{capitalizeFirstLetter(orderData?.prod_name)}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Target Quantity</Text>
                <Text>{orderData?.quantityavailable}</Text>
              </Space>

              <Space direction="vertical">
                <Text strong>Files</Text>
                <a href={orderData?.files} target="_blank" rel="noreferrer">
                  {orderData?.files?.split("/").pop()}
                </a>
              </Space>
              <Space direction="vertical">
                <Text strong>Comment</Text>
                <Text>{orderData?.comment}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Price</Text>
                <Text>{orderData?.price}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Shipping</Text>
                <Text>{orderData?.shipping}</Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>

      {/* User Quotation */}
      <Modal
        open={openUser}
        onCancel={() => {
          setOpenUser(false);
          setUserData();
        }}
        title={"User Details"}
        width={800}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenUser(false);
              setUserData();
            }}
          >
            Back
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Name</Text>
                <Text>
                  {userData?.firstName} {userData?.lastName}
                </Text>
              </Space>
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Contact Details</Text>
                <Text>{userData?.email}</Text>
                <Text>{userData?.mobileNumber}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Vip Status</Text>
                <Text>
                  {" "}
                  {userData?.isVIP === false ? (
                    <span style={{ color: "red" }}>No</span>
                  ) : (
                    <span style={{ color: "green" }}>Yes</span>
                  )}
                </Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>

      {/* User Quotation */}
      <Modal
        open={openUser}
        onCancel={() => {
          setOpenUser(false);
          setUserData();
        }}
        title={"User Details"}
        width={800}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenUser(false);
              setUserData();
            }}
          >
            Back
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Name</Text>
                <Text>
                  {userData?.firstName} {userData?.lastName}
                </Text>
              </Space>
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Contact Details</Text>
                <Text>{userData?.email}</Text>
                <Text>{userData?.mobileNumber}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Vip Status</Text>
                <Text>
                  {" "}
                  {userData?.isVIP === false ? (
                    <span style={{ color: "red" }}>No</span>
                  ) : (
                    <span style={{ color: "green" }}>Yes</span>
                  )}
                </Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>
      {/* modal of notes */}

      <Modal
        open={openNotes}
        onCancel={() => {
          setOpenNotes(false);
          resetNotes();
        }}
        title={"Set Notes"}
        width={1000}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenNotes(false);
            }}
          >
            Cancel
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>
                  Product Name : {notesData?.rfqData?.prodName}
                </Text>
                <Text>
                  {/* gets the no note */}
                  <ul>
                    {notesData.total > 0 ? (
                      //getting the note
                      <ul
                        style={{
                          backgroundColor: "#FAFAFA",
                          width: "600px",
                          borderRadius: "10px",
                          padding: "15px",
                          listStyle: "none",
                          maxHeight: "400px",
                          overflow: "auto",
                        }}
                      >
                        {notesData.notes
                          .sort(
                            (a, b) =>
                              new Date(b.createdAt) - new Date(a.createdAt)
                          )
                          .map((note) => (
                            <li
                              key={note._id}
                              style={{
                                backgroundColor: "white",
                                borderRadius: "10px",
                                padding: "5px",
                                margin: "2px",
                                border: "#ECECEC solid 1px",
                              }}
                            >
                              <span
                                style={{
                                  color: "green",
                                  fontSize: "11px",
                                  paddingRight: "20px",
                                }}
                              >
                                {formatDateWithTime(note.createdAt)}:
                              </span>
                              {note.notes}{" "}
                            </li>
                          ))}
                      </ul>
                    ) : (
                      <Text>No notes</Text>
                    )}
                  </ul>
                </Text>
              </Space>
            </Space>
          </Col>

          <Col span={12} push={3}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Add Notes</Text>
                <Input.TextArea
                  style={{ width: "350px", height: "250px" }}
                  value={notes}
                  onChange={(newnotes) => {
                    setNotes(newnotes.target.value);
                  }}
                ></Input.TextArea>

                <Button
                  style={{
                    width: "350px",
                    fontSize: "20px",
                    height: "40px",
                    backgroundColor: "#82CD47",
                  }}
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={handleNotesSubmit}
                >
                  Submit
                </Button>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>

      {/* search */}
      <Text>
        <Input
          style={{ margin: "20px", width: "600px" }}
          placeholder="Search Product Name"
          value={searchValue}
          onChange={(e) => {
            const currValue = e.target.value;
            setSearchValue(currValue);
            const filteredData = rfqRow.filter((entry) => {
              if (
                entry.prodName.toLowerCase().includes(currValue.toLowerCase())
              ) {
                return entry;
              }
              return null;
            });
            setSearchData(filteredData);
          }}
          disabled={!rfqRow || rfqRow.length === 0}
        />
      </Text>

      <Table
        columns={actionColumn}
        dataSource={searchData.length > 0 ? searchData : rfqRow}
        pagination={{ pageSize: 5 }}
        rowKey="_id"
      />
    </>
  );
};
