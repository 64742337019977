import React, { useState, useEffect, useRef, useMemo } from "react";

import {
  Table,
  Button,
  Modal,
  Typography,
  Space,
  Form,
  Input,
  Select,
  Spin,
  Divider,
} from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import DefaultLoader from "../Shared/loader";
import {
  createProduct,
  editProduct,
  getProducts,
  getSellersByProduct,
  removeProduct,
} from "../../api/product.api";
import sellerSearchStore from "../../store/seller_search.store";
import debounce from "lodash/debounce";
import _ from "lodash";
import {
  addSellerToProduct,
  removeSellerFromProduct,
  searchSeller,
} from "../../api/seller.api";
const { Title } = Typography;
const { confirm } = Modal;

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const setSellers = sellerSearchStore((state) => state.setSellers);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      if (value === "") return;
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        const mappedData = newOptions.data.map((entry) => {
          return {
            value: entry._id,
            label: entry.name,
          };
        });
        setSellers(newOptions.data);
        setOptions(mappedData);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

export const ProductsTable = () => {
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [userRow, setUserRow] = useState([]);
  const [currentEditId, setCurrentEditId] = useState("a");
  const [searchData, setSearchData] = useState([]); // This is the data that will be filtered
  const [isLoading, setIsLoading] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [error, setError] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [productSellers, setProductSellers] = useState([]);
  const [openSellers, setOpenSellers] = useState(false);
  const [productId, setProductId] = useState("");
  const [sellerTableLoading, setSellerTableLoading] = useState(false);
  const [sellerSearchValue, setSellerSearchValue] = useState(null);
  const [currentProductData, setCurrentProductData] = useState({});

  const editForm = useRef(null);
  const searchedSellers = sellerSearchStore((state) => state.searchedSellers);

  const handleOpenSellers = async (id) => {
    setProductSellers([]);
    setProductId(id);
    setOpenSellers(true);
    setSellerTableLoading(true);

    const sellers = await getSellersByProduct(id);
    setProductSellers(sellers.data);
    setSellerTableLoading(false);
  };

  const handleRemoveSellerFromProduct = async (id) => {
    const newData = productSellers.filter((entry) => entry._id !== id);
    setProductSellers(newData);
    await removeSellerFromProduct(id);
  };

  const handleAddSellerToProduct = async (data) => {
    return await addSellerToProduct(data);
  };

  const handleDelete = async (id) => {
    await removeProduct(id);
    const newData = userRow.filter((entry) => entry._id !== id);
    setUserRow(newData);
  };

  const handleCreateProduct = async (values) => {
    try {
      setButtonLoader(true);
      await createProduct(values);
      setUserRow((prevData) => [...prevData, values]);
      setButtonLoader(false);
      setOpenCreate(false);
    } catch (error) {
      setError(error);
      setButtonLoader(false);
    }
  };

  const handleEditSeller = async (values) => {
    setButtonLoader(true);
    await editProduct(currentEditId, values);
    const newData = userRow.map((entry) => {
      if (entry._id === currentEditId) {
        return { ...entry, ...values };
      }
      return entry;
    });
    setUserRow(newData);
    setOpenEdit(false);
    setButtonLoader(false);
  };

  const sellersColumn = [
    {
      dataIndex: "sellerName",
      title: "Name",
      sorter: (a, b) => a.name?.length - b.name?.length,
      key: "sellerName",
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile Number",
      key: "mobileNumber",
    },
    {
      dataIndex: "email",
      sorter: (a, b) => a.email?.length - b.email?.length,
      title: "Email",
      key: "email",
    },

    {
      dataIndex: "industryType",
      sorter: (a, b) => a.industryType?.length - b.industryType?.length,
      title: "Industry Type",
      key: "industryType",
    },

    {
      dataIndex: "address",
      title: "Address",
      key: "address",
    },

    {
      dataIndex: "action",
      title: "Action",
      key: "_id",
      render: (_, params) => {
        return (
          <>
            <Button
              size="small"
              type="primary"
              danger
              onClick={() => {
                confirm({
                  title: "Do you want to remove seller?",
                  icon: <ExclamationCircleFilled />,
                  onOk() {
                    handleRemoveSellerFromProduct(params._id);
                  },
                });
              }}
            >
              Remove
            </Button>
          </>
        );
      },
    },
  ];

  const actionColumn = [
    {
      dataIndex: "name",
      title: "Name",
      sorter: (a, b) => a.name.length - b.name.length,
      key: "name",
    },
    // {
    //   dataIndex: "description",
    //   title: "Description",
    //   key: "description",
    //   ellipsis:true,
    // },
    {
      dataIndex: "brand",
      sorter: (a, b) => a.email.length - b.email.length,
      title: "Brand",
      key: "brand",
    },

    {
      dataIndex: "dimension",
      title: "Dimensions",
      key: "dimension",
    },

    {
      dataIndex: "weight",
      title: "Weight",
      key: "weight",
    },
    {
      dataIndex: "productLink",
      title: "Product Link",
      key: "productLink",
      render: (_, params) => {
        // Visit text
        return params.productLink ? (
          <a target="_blank" rel="noreferrer" href={params.productLink}>
            Visit
          </a>
        ) : null;
      },
    },

    {
      dataIndex: "action",
      title: "Action",
      key: "_id",
      render: (_, params) => {
        return (
          <>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                handleOpenSellers(params._id);
                setCurrentProductData(params);
              }}
            >
              Sellers
            </Button>
            <Divider type="vertical" />
            <Button
              size="small"
              type="primary"
              onClick={() => {
                setOpenEdit(true);
                setCurrentEditId(params._id);
                setTimeout(() => {
                  editForm.current.setFieldsValue(params);
                }, 100);
              }}
            >
              Edit
            </Button>
            <Divider type="vertical" />
            <Button
              size="small"
              type="primary"
              danger
              onClick={() => {
                confirm({
                  title: "Do you want to delete seller?",
                  icon: <ExclamationCircleFilled />,
                  onOk() {
                    handleDelete(params._id);
                  },
                });
              }}
            >
              Remove
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProducts();
        const data = await response.data;
        setUserRow(data.rows);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return <DefaultLoader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Title variant="h3">List of Products</Title>
      <Modal
        open={openCreate}
        onCancel={() => {
          setOpenCreate(false);
        }}
        footer={null}
        title={"Create Product"}
        // width={800}
      >
        <>
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            style={{ maxWidth: 600 }}
            onFinish={handleCreateProduct}
          >
            <Form.Item
              label="Product Name"
              name="name"
              rules={[
                { required: true, message: "Please input your Product Name!" },
              ]}
            >
              <Input placeholder="Product Name" />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <Input placeholder="Description" />
            </Form.Item>
            <Form.Item label="Brand" name="brand">
              <Input placeholder="Brand" />
            </Form.Item>
            <Form.Item label="Dimensions" name="dimension">
              <Input placeholder="Dimensions" />
            </Form.Item>
            <Form.Item label="Weight" name="weight">
              <Input placeholder="Weight" />
            </Form.Item>
            <Form.Item label="Link" name="productLink">
              <Input placeholder="Link" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={buttonLoader}
                block
              >
                Create
              </Button>
            </Form.Item>
          </Form>
        </>
      </Modal>

      <Modal
        open={openSellers}
        onCancel={() => {
          setOpenSellers(false);
        }}
        footer={null}
        title={"Sellers"}
        width={800}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <DebounceSelect
              showSearch
              value={sellerSearchValue}
              placeholder="Add Seller"
              fetchOptions={searchSeller}
              onChange={async (newValues) => {
                setSellerSearchValue(null);
                const selectedSeller = searchedSellers.find(
                  (entry) => entry._id === newValues.value
                );

                const sellerClone = _.cloneDeep(selectedSeller);
                const currentProductDataClone = _.cloneDeep(currentProductData);
                delete sellerClone._id;

                delete currentProductDataClone._id;
                setSellerTableLoading(true);
                const res = await handleAddSellerToProduct({
                  ...sellerClone,
                  ...currentProductDataClone,
                  productName: currentProductDataClone.name,
                  sellerName: selectedSeller.name,
                  productId: productId,
                  sellerId: selectedSeller._id,
                });
                setProductSellers((prevData) => [
                  ...prevData,
                  {
                    ...selectedSeller,
                    sellerName: selectedSeller.name,
                    _id: res.data._id,
                  },
                ]);
                setSellerTableLoading(false);
              }}
              style={{ width: "300px" }}
            />
          </div>
          <Table
            dataSource={productSellers}
            loading={sellerTableLoading}
            columns={sellersColumn}
          ></Table>
        </Space>
      </Modal>
      <Modal
        open={openEdit}
        onCancel={() => {
          setOpenEdit(false);
        }}
        footer={null}
        title={"Edit Product"}
        // width={800}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Form
            ref={editForm}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            style={{ maxWidth: 600 }}
            onFinish={handleEditSeller}
          >
            <Form.Item
              label="Product Name"
              name="name"
              rules={[
                { required: true, message: "Please input your Product Name!" },
              ]}
            >
              <Input placeholder="Product Name" />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <Input placeholder="Description" />
            </Form.Item>
            <Form.Item label="Brand" name="brand">
              <Input placeholder="Brand" />
            </Form.Item>
            <Form.Item label="Dimensions" name="dimension">
              <Input placeholder="Dimensions" />
            </Form.Item>
            <Form.Item label="Weight" name="weight">
              <Input placeholder="Weight" />
            </Form.Item>

            <Form.Item label="Link" name="productLink">
              <Input placeholder="Link" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={buttonLoader}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Modal>
      <div>
        <Input
          style={{ width: "300px" }}
          placeholder="Search"
          value={searchValue}
          onChange={(e) => {
            const currValue = e.target.value;
            setSearchValue(currValue);
            const filteredData = userRow.filter((entry) => {
              if (
                entry.name.toLowerCase().includes(currValue.toLowerCase()) ||
                entry.description
                  .toLowerCase()
                  .includes(currValue.toLowerCase()) ||
                entry.brand.toLowerCase().includes(currValue.toLowerCase())
              ) {
                return entry;
              }
              return null;
            });
            setSearchData(filteredData);
          }}
        />

        <Button
          style={{ float: "right" }}
          type="primary"
          onClick={() => {
            setOpenCreate(true);
          }}
        >
          Create Product
        </Button>
      </div>
      <Divider />
      <Table
        columns={actionColumn}
        dataSource={searchData.length > 0 ? searchData : userRow}
        pagination={{ pageSize: 5 }}
        rowKey="_id"
      />
    </>
  );
};
