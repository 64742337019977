import { useEffect, useState } from "react";
import { getTotal } from "../../api/user.api";
import { getRfqTotal } from "../../api/requestForQuotation.api";
import { Statistic, Row, Col, Card, Typography, Divider } from "antd";

import CountUp from "react-countup";

import WeeklyBar from "../../components/datatable/bar_datas/bar/bar.chart";
import RfqLine from "../../components/datatable/bar_datas/line/line.data";

const { Title } = Typography;

const Home = () => {
  const [data, setData] = useState({});
  const [rfqData, setRfqData] = useState({});

  const formatter = (value) => <CountUp end={value} separator="," />;

  const getData = async () => {
    const response = await getTotal();
    setData(response.data);
  };

  const getRfqData = async () => {
    const response = await getRfqTotal();
    setRfqData(response.data);
  };

  useEffect(() => {
    getData();
    getRfqData();
  }, []);

  return (
    <>
      <Title level={3}>Quotations</Title>
      <Row gutter={20}>
        <Col span={5}>
          <Card bordered={true}>
            <Statistic
              title="Request For Order"
              value={rfqData?.pending}
              formatter={formatter}
            />
          </Card>
        </Col>
        <Col span={5}>
          <Card bordered={true}>
            <Statistic
              title="Orders"
              value={rfqData?.ordered}
              formatter={formatter}
            />
          </Card>
        </Col>
        <Col span={5}>
          <Card bordered={true}>
            <Statistic
              title="Verified"
              value={rfqData?.verified}
              formatter={formatter}
            />
          </Card>
        </Col>
        <Col span={5}>
          <Card bordered={true}>
            <Statistic
              title="Shipped"
              value={rfqData?.shipped}
              formatter={formatter}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card bordered={true}>
            <Statistic
              title="Delivered"
              value={rfqData?.delivered}
              formatter={formatter}
            />
          </Card>
        </Col>
      </Row>
      <Divider />

      <Row gutter={20}>
        <Col span={12}>
          <Row>
            <Divider>
              <Title level={3}> Users</Title>
            </Divider>
            <Col span={12} style={{ padding: "5px" }}>
              <Card bordered={true}>
                <Statistic
                  title="Unverified Users"
                  value={data?.noverify}
                  formatter={formatter}
                />
              </Card>
            </Col>
            <Col span={12} style={{ padding: "5px" }}>
              <Card bordered={true}>
                <Statistic
                  title="Verified Users"
                  value={data?.verify}
                  formatter={formatter}
                />
              </Card>
            </Col>
            <Col span={12} style={{ padding: "5px" }}>
              <Card bordered={true}>
                <Statistic
                  title="VIP Users"
                  value={data?.vip}
                  formatter={formatter}
                />
              </Card>
            </Col>
            <Col span={12} style={{ padding: "5px" }}>
              <Card bordered={true}>
                <Statistic title="Sellers" value={999} formatter={formatter} />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          {/* <ReactECharts option={option} /> */}
          <WeeklyBar />
        </Col>
      </Row>
      <Divider />

      <Row gutter={[8, 8]}>
        <Col span={24}>
          <RfqLine />
        </Col>
      </Row>
    </>
  );
};

export default Home;
