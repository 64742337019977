import React, { useState, useEffect } from "react";
import { getInvoice, viewRFQ } from "../../api/history.api";
import { Table, Button, Modal, Row, Col, Typography, Space } from "antd";
import { formatDate, capitalizeFirstLetter } from "../../helpers/utils";
import DefaultLoader from "../Shared/loader";

const { Text, Title } = Typography;

export const InvoiceData = () => {
  const [viewData, setViewData] = useState({});
  const [invData, setInvData] = useState({});
  const [openView, setOpenView] = React.useState(false);
  const [openInv, setOpenInv] = React.useState(false);
  const [rfqRow, setRfqRow] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  //const [loading, setLoading] = useState(false);

  const handleView = async (data) => {
    setViewData(data);
    setOpenView(true);
  };

  const handleInvoice = async (id) => {
    const response = await viewRFQ(id);
    if (response.status === 200) {
      setInvData(response.data);
      setOpenInv(true);
    }
  };

  const actionColumn = [
    { dataIndex: "_id", title: "ID", width: 210, key: "_id" },
    { dataIndex: "tax", title: "TAX", width: 150, key: "tax" },
    { dataIndex: "service", title: "SERVICE FEE", width: 150, key: "service" },
    {
      dataIndex: "due",
      title: "DUE DATE",
      key: "due",
      render(_, params) {
        return (
          <div>
            <p>{formatDate(params.due)}</p>
          </div>
        );
      },
    },

    {
      dataIndex: "createdAt",
      title: "CREATED AT",
      key: "createdAt",
      render(_, params) {
        return (
          <div>
            <p>{formatDate(params.createdAt)}</p>
          </div>
        );
      },
    },
    {
      dataIndex: "action",
      title: "Action",
      key: "_id",
      render: (_, params) => {
        return (
          <Space direction="horizontal">
            <Button
              type="primary"
              onClick={() => {
                handleView(params);
              }}
            >
              View
            </Button>

            <Button
              type="dashed"
              onClick={() => {
                handleInvoice(params._id);
              }}
            >
              View Full Invoice
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getInvoice();
        const data = await response.data;
        setRfqRow(data.rows);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return <DefaultLoader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Space direction="vertical" style={{ padding: "15px" }}>
      <Title variant="h3">Invoice</Title>
      {/* Another modal for viewing all details of the selected data */}
      <Modal
        open={openView}
        onCancel={() => {
          setOpenView(false);
        }}
        footer={null}
        title={"View Details"}
        width={800}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>ID</Text>
                <Text>{viewData?._id}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>RFQ ID</Text>
                <Text>{viewData?.rfqId}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Tax</Text>
                <Text>{viewData?.tax}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Service</Text>
                <Text>{viewData?.service}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Due Date</Text>
                <Text>{formatDate(viewData?.due)}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Created At</Text>
                <Text>{formatDate(viewData?.createdAt)}</Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>

      <Modal
        open={openInv}
        onCancel={() => {
          setOpenInv(false);
          setInvData();
        }}
        title={"Invoice Full Details"}
        width={800}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenInv(false);
              setInvData();
            }}
          >
            Back
          </Button>,

          <Button
            key="Send me"
            type="primary"
            onClick={() => {
              setOpenInv(false);
              setInvData();
            }}
          >
            Email me a copy (Under Construction)
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={16}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong> Invoice Id #: {invData?.invoiceData?._id}</Text>
              </Space>
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>BILLED TO:</Text>
                <Text>{invData?.userData?.name}</Text>
                <Text>{invData?.userData?.address}</Text>
              </Space>
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="horizontal">
                <Text strong>Due Date:</Text>
                <Text>{invData?.duedate}</Text>
              </Space>
            </Space>
          </Col>

          <Col span={16}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong> </Text>
              </Space>
            </Space>
          </Col>

          <Col span={16}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>
                  Item Name:{" "}
                  <Text>
                    {capitalizeFirstLetter(invData?.prodData?.prodname)}
                  </Text>
                </Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Desctiption</Text>
                <Text>{invData?.prodData?.description}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>
                  Quantity: <Text>{invData?.prodData?.quantity}</Text>
                </Text>
              </Space>
            </Space>
          </Col>
          <Col span={16}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>
                  ____________________________________________________________________________________{" "}
                </Text>
              </Space>
            </Space>
          </Col>

          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>SERVICE: </Text>
                <Text strong> </Text>

                <Text strong>Total Price </Text>
                <Text strong>Shipping </Text>
                <Text strong>Tax </Text>
                <Text strong>Service Fee </Text>
                <Text strong>Total </Text>
              </Space>
            </Space>
          </Col>

          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong> COST: </Text>
                <Text strong>
                  {invData?.service?.TotalPrice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                </Text>
                <Text strong>
                  {invData?.service?.Shipping.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                </Text>
                <Text strong>
                  {invData?.service?.Tax.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                </Text>
                <Text strong>
                  {invData?.service?.ServiceFee.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                </Text>
                <Text strong>
                  {invData?.service?.Total.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                </Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>

      <Table
        columns={actionColumn}
        dataSource={rfqRow}
        pagination={{ pageSize: 5 }}
        rowKey="_id"
      />
    </Space>
  );
};
