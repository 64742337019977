import { Routes, Route, BrowserRouter } from "react-router-dom";
import useAuthStore from "./store/auth.store";
//functions
import { history } from "./helpers/history";
import PrivateRoute from "./helpers/PrivateRoute";
import PublicRoute from "./helpers/PublicRoute";

//pages
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Rfq from "./pages/rfq/Rfq";
import RfqManagement from "./pages/rfq/rfqManager";
import Rejectedrfq from "./pages/rfq/rfqRejected";
import RfqPayment from "./pages/rfq/rfqPayment";
import InvoiceTable from "./pages/reports/invoice";
import Users from "./pages/users/Users";
import Sellers from "./pages/products/sellers";
import VerifyUser from "./pages/users/verifyUser";
import NotFound from "./pages/404/404";
import Messages from "./pages/messages";
import { useEffect, useState } from "react";
import DefaultLoader from "./components/Shared/loader";
import Products from "./pages/products/products";
import VipPayed from "./pages/users/vipPayed";
import Blog from "./pages/cms/Blog";
import Tracking from "./pages/cms/Tracking";
import Manualtracking from "./pages/cms/Manual_tracking";

function AppRoutes() {
  const loggedIn = useAuthStore((state) => state.loggedIn);
  const setLoggedIn = useAuthStore((state) => state.setLoggedIn);
  const setLoggedOut = useAuthStore((state) => state.setLoggedOut);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setLoggedIn();
    } else {
      setLoggedOut();
    }
    setIsReady(true);
  }, [setLoggedIn, setLoggedOut]);

  if (!isReady) {
    return <DefaultLoader />;
  }

  return (
    <BrowserRouter history={history}>
      <Routes>
        <Route element={<PublicRoute isAllowed={loggedIn} />}>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
        </Route>

        <Route
          path="/dashboard"
          element={
            <PrivateRoute isAllowed={loggedIn}>
              <Home />
            </PrivateRoute>
          }
          isAllowed={loggedIn}
        />
        <Route
          path="/rfq"
          element={
            <PrivateRoute isAllowed={loggedIn}>
              <Rfq />
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute isAllowed={loggedIn}>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path="/user-management"
          element={
            <PrivateRoute isAllowed={loggedIn}>
              <VerifyUser />
            </PrivateRoute>
          }
        />

        <Route
          path="/sellers"
          element={
            <PrivateRoute isAllowed={loggedIn}>
              <Sellers />
            </PrivateRoute>
          }
        />
        <Route
          path="/products"
          element={
            <PrivateRoute isAllowed={loggedIn}>
              <Products />
            </PrivateRoute>
          }
        />

        <Route
          path="/invoice"
          element={
            <PrivateRoute isAllowed={loggedIn}>
              <InvoiceTable />
            </PrivateRoute>
          }
        />

        <Route
          path="/quotation-management"
          element={
            <PrivateRoute isAllowed={loggedIn}>
              <RfqManagement />
            </PrivateRoute>
          }
        />

        <Route
          path="/declined-management"
          element={
            <PrivateRoute isAllowed={loggedIn}>
              <Rejectedrfq />
            </PrivateRoute>
          }
        />
        <Route
          path="/messages"
          element={
            <PrivateRoute isAllowed={loggedIn}>
              <Messages />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment-management"
          element={
            <PrivateRoute isAllowed={loggedIn}>
              <RfqPayment />
            </PrivateRoute>
          }
        />

        <Route
          path="/vip-payed"
          element={
            <PrivateRoute isAllowed={loggedIn}>
              <VipPayed />
            </PrivateRoute>
          }
        />

        <Route
          path="/blog"
          element={
            <PrivateRoute isAllowed={loggedIn}>
              <Blog />
            </PrivateRoute>
          }
        />

      <Route
          path="/tracking"
          element={
            <PrivateRoute isAllowed={loggedIn}>
              <Tracking />
            </PrivateRoute>
          }
        />

      <Route
          path="/manual-tracking"
          element={
            <PrivateRoute isAllowed={loggedIn}>
              <Manualtracking />
            </PrivateRoute>
          }
        />

        {/* </PrivateRoute> */}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
