import axios from "../helpers/axios";

export const getBlog = async () => {
  return axios
    .get("/api/content/")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
