import React, { useState } from "react";
import { Nonverifytable } from "../../components/datatable/usernotverifiedData";
import { Verifytable } from "../../components/datatable/userverifiedData";
import { NotVipUsers } from "../../components/datatable/usernotvip";
import { VipUsers } from "../../components/datatable/uservipData";
//NotVipUsers
import { Tabs, Divider, Typography } from "antd";
const { Title } = Typography;
const { TabPane } = Tabs;

const VerifyUser = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [activeStatus, setActiveStatus] = useState("Non-Verify Users");

  const paymentStatuses = [
    "Non-Verify Users",
    "Verify Users",
    "Non-VIP Users",
    "VIP Users",
  ];

  const getStatusContent = (status) => {
    switch (status) {
      case "Non-Verify Users":
        return <Nonverifytable key={activeTab} />;
      case "Verify Users":
        return <Verifytable key={activeTab} />;
      case "Non-VIP Users":
        return <NotVipUsers key={activeTab} />;
      case "VIP Users":
        return <VipUsers key={activeTab} />;
      default:
        return null;
    }
  };

  const handleTabChange = (key) => {
    setActiveTab(key);

    switch (key) {
      case "1":
        setActiveStatus("Non-Verify Users");
        break;
      case "2":
        setActiveStatus("Verify Users");
        break;
      case "3":
        setActiveStatus("Non-VIP Users");
        break;
      case "4":
        setActiveStatus("VIP Users");
        break;
      default:
        return null;
    }
  };

  return (
    <>
      <Divider orientation="left">
        {" "}
        <Title variant="h5">List of {activeStatus}</Title>
      </Divider>

      <Tabs
        tabBarGutter={150}
        defaultActiveKey={activeTab}
        centered
        onChange={handleTabChange}
      >
        {paymentStatuses.map((status, index) => (
          <TabPane
            tab={status}
            key={String(index + 1)}
            style={{ padding: "15px" }}
          >
            {getStatusContent(status)}
          </TabPane>
        ))}
      </Tabs>
    </>
  );
};

export default VerifyUser;
