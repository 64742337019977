import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { getDeclined } from "../../api/requestForQuotation.api";
import { requoteOrder } from "../../api/order.api";
import {
  Table,
  Button,
  Tag,
  Modal,
  Row,
  Col,
  Typography,
  Space,
  Image,
  Input,
  InputNumber,
  DatePicker,
  Select,
} from "antd";
import Swal from "sweetalert2";
import {
  capitalizeFirstLetter,
  formatDate,
  formatDate2,
} from "../../helpers/utils";
import DefaultLoader from "../Shared/loader";

const { Text, Title } = Typography;

export const DeclinedData = () => {
  const [viewData, setViewData] = useState({});
  const [openView, setOpenView] = React.useState(false);
  const [rfqRow, setRfqRow] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleView = async (data) => {
    setViewData(data);
    setOpenView(true);
  };

  //state for requote
  const [requotationData, setRequotationData] = useState({});
  const [openRequotation, setOpenRequotation] = React.useState(false);
  const [deadline, setDeadline] = useState(dayjs());
  const [dateString, setDateString] = useState("");
  const [price, setPrice] = useState(1.0);
  const [quantity, setQuantity] = useState(1);
  const [shipping, setShipping] = useState(1);
  const [priority, setPriority] = useState("low");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  const handleRequotation = async (data) => {
    setRequotationData(data);
    setDeadline(dayjs(data.deadline));
    setDateString(formatDate2(data.deadline));
    setQuantity(data.targetqty);
    setOpenRequotation(true);
  };

  const onChange = (date, dateStr) => {
    setDeadline(dayjs(dateStr));
    setDateString(dateStr);
  };

  const resetRequotation = () => {
    setPrice(1.0);
    setQuantity(1);
    setShipping(1);
    setComment("");
    setDeadline(dayjs());
  };

  const handleRequotationSubmit = async () => {
    // check price, shipping,deadline
    if (price <= 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Price must be greater than 0",
      });
      return;
    }
    if (shipping === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Shipping must not be empty",
      });
      return;
    }
    if (dateString === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Deadline must not be empty",
      });
      return;
    }

    const params = {
      delivery_address_id:
        requotationData.deliveryAddressId || "No Delivery Address",
      rfqId: requotationData._id,
      price: price,
      shipping: shipping,
      comment: comment,
      deadline: dateString, // using yyyy-mm-dd format
      image: requotationData.image,
      priority: priority,
      prod_name: requotationData.prodName,
      quantityavailable: quantity,
    };
    setLoading(true);
    try {
      const response = await requoteOrder(params);
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Quotation created successfully",
        });
        setOpenRequotation(false);
        resetRequotation();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const actionColumn = [
    { dataIndex: "_id", title: "ID", width: 210, key: "_id" },
    {
      dataIndex: "rfq",
      title: "Image",
      width: 100,
      key: "rfq",
      render: (_, params) => {
        return (
          <Image
            src={params.image}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://antswelead.sgp1.digitaloceanspaces.com/internal_files/335623101_583997373674210_5297548643792796750_n.jpg";
            }}
            alt="img"
            width={80}
          />
        );
      },
    },
    {
      dataIndex: "prodName",
      title: "PRODUCT NAME",
      width: 170,
      key: "prodName",
    },
    { dataIndex: "targetqty", title: "QUANTITY", width: 100, key: "targetqty" },
    {
      dataIndex: "deadline",
      title: "DEADLINE",
      width: 170,
      key: "deadline",
      render(_, params) {
        return (
          <div>
            <p>{formatDate(params.deadline)}</p>
          </div>
        );
      },
    },

    {
      dataIndex: "status",
      title: "Status",
      width: 130,
      key: "_id",
      render: (_, params) => {
        return (
          <Tag color={params.status === "pending" ? "magenta" : "green"}>
            {capitalizeFirstLetter(params.status)}
          </Tag>
        );
      },
    },
    {
      dataIndex: "action",
      title: "Action",
      width: 230,
      key: "_id",
      render: (_, params) => {
        return (
          <Space direction="horizontal">
            <Button
              type="primary"
              onClick={() => {
                handleView(params);
              }}
            >
              View
            </Button>
            <Button
              style={{ width: "109.09px" }}
              size="small"
              type="dashed"
              onClick={() => {
                handleRequotation(params);
              }}
            >
              Re-Quotate
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDeclined();
        const data = await response.data;
        setRfqRow(data.Requests);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return <DefaultLoader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Space direction="vertical" style={{ padding: "15px" }}>
      <Title variant="h3">Pending Quotations</Title>
      {/* Another modal for viewing all details of the selected data */}
      <Modal
        open={openView}
        onCancel={() => {
          setOpenView(false);
        }}
        footer={null}
        title={"View Details"}
        width={800}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Image src={viewData?.image} alt="img" width={300} />
          </Col>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Product Name</Text>
                <Text>{viewData?.prodName}</Text>
              </Space>
              {/* <Space direction="vertical">
                <Text strong>Target Quantity</Text>
                <Text>{viewData?.targetqty}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Deadline</Text>
                <Text>{viewData?.deadline}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Status</Text>
                <Text>{viewData?.status}</Text>
              </Space> */}
              <Space direction="vertical">
                <Text strong>Reason for Decline</Text>
                <Text>{viewData?.notes}</Text>
              </Space>
              {/* <Space direction="vertical">
                <Text strong>Specifications</Text>
                <Text>{viewData?.specs}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Files</Text>
                <a href={viewData?.files} target="_blank" rel="noreferrer">
                  {viewData?.files?.split("/").pop()}
                </a>
              </Space> */}
            </Space>
          </Col>
        </Row>
      </Modal>

      <Modal
        open={openRequotation}
        onCancel={() => {
          setOpenRequotation(false);
          resetRequotation();
        }}
        title={"Set Re-Quotation"}
        width={800}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenRequotation(false);
              resetRequotation();
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleRequotationSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical">
              <Image src={requotationData?.image} alt="img" width={300} />
              <Space direction="vertical">
                <Text strong>Product Name</Text>
                <Text>{capitalizeFirstLetter(requotationData?.prodName)}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Target Quantity</Text>
                <Text>{requotationData?.targetqty}</Text>
              </Space>

              <Space direction="vertical">
                <Text strong>Status</Text>
                <Text>{capitalizeFirstLetter(requotationData?.status)}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Description</Text>
                <Text>{requotationData?.description}</Text>
              </Space>
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical">
              <Space direction="vertical">
                <Text strong>Specifications</Text>
                <Text>{requotationData?.specs}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Files</Text>
                <a
                  href={requotationData?.files}
                  target="_blank"
                  rel="noreferrer"
                >
                  {requotationData?.files?.split("/").pop()}
                </a>
              </Space>
              <Space direction="vertical">
                <Text strong>Deadline</Text>
                {openRequotation ? (
                  <DatePicker
                    onChange={onChange}
                    defaultValue={deadline}
                    value={deadline}
                  />
                ) : null}
              </Space>
              <Space direction="vertical">
                <Text strong>Price</Text>
                <InputNumber
                  step="0.01"
                  value={price}
                  onChange={(newPrice) => {
                    setPrice(newPrice);
                  }}
                ></InputNumber>
              </Space>
              <Space direction="vertical">
                <Text strong>Quantity</Text>
                <InputNumber
                  value={quantity}
                  onChange={(newQuantity) => {
                    setQuantity(newQuantity);
                  }}
                ></InputNumber>
              </Space>
              <Space direction="vertical">
                <Text strong>Priority</Text>
                <Select
                  defaultValue="low"
                  style={{ width: 120 }}
                  onChange={(value) => {
                    setPriority(value);
                  }}
                  options={[
                    { value: "low", label: "Low" },
                    { value: "medium", label: "Medium" },
                    { value: "high", label: "High" },
                  ]}
                />
              </Space>
              <Space direction="vertical">
                <Text strong>Shipping Fee</Text>
                <InputNumber
                  step="0.01"
                  value={shipping}
                  onChange={(newQuantity) => {
                    setShipping(newQuantity);
                  }}
                ></InputNumber>
              </Space>
              <Space direction="vertical">
                <Text strong>Comments</Text>
                <Input.TextArea
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                ></Input.TextArea>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>

      <Table
        columns={actionColumn}
        dataSource={rfqRow}
        pagination={{ pageSize: 5 }}
        rowKey="_id"
      />
    </Space>
  );
};
