import React, { useEffect, useState } from "react";
import { Layout, Typography, theme, Skeleton } from "antd";
import { userSearch } from "../../api/user.api";
import useUserStore from "../../store/user.store";
import moment from "moment";
import { io } from "socket.io-client";
import _ from "lodash";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Search,
  ConversationList,
  Conversation,
  ConversationHeader,
  Avatar,
  InfoButton,
  // MessageSeparator,
  ExpansionPanel,
  Sidebar as ChatSidebar,
} from "@chatscope/chat-ui-kit-react";

const { Title, Text } = Typography;
const { Header, Content } = Layout;
const URL = "https://chat.weleadants.com";

const Messages = () => {
  const [messageInputValue, setMessageInputValue] = React.useState("");
  const [searchedUser, setSearchUser] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchInputPlaceholder, setSearchInputPlaceholder] = useState("");
  const [activeChatId, setActiveChatId] = useState("");
  const [messages, setMessages] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [activeChatInfo, setActiveChatInfo] = useState({});
  const [conversations, setConversations] = useState([]);
  const user = useUserStore((state) => state.user);
  const socket = io(URL);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const defaultAvatar = "/chat-avatar.jpeg";

  const search = (searchInput) => {
    userSearch(searchInput).then((res) => {
      console.log(user.email);
      // remove current user from search results using user.email
      res.data = res.data.filter((det) => det.email !== user.email);
      setSearchUser([...res.data]);
      setSearchInput(searchInput);
    });
  };

  //opening a room
  const openRoom = (selectedUser) => {
    // setActiveChatInfo(selectedUser);
    setSearchInputPlaceholder("");
    let alternateRecipientId = "";
    if (selectedUser?.members) {
      selectedUser.members.forEach((member) => {
        if (member.userId !== user._id) {
          alternateRecipientId = `${member.userId}`;
        }
      });
    }

    socket.emit(
      "call",
      "chat.getProfile",
      {
        userId: alternateRecipientId ? alternateRecipientId : selectedUser?._id,
      },
      (err, res) => {
        setActiveChatInfo(res);
      }
    );

    socket.emit(
      "call",
      "chat.joinRoom",
      {
        senderId: user._id,
        recipientId: alternateRecipientId
          ? alternateRecipientId
          : selectedUser?._id,
      },
      function (err, res) {
        if (err) {
          console.error(err);
        } else {
          setActiveChatId(res.chatId);
          setSearchInput("");
          getRoomMessages(res.chatId);
        }
      }
    );
  };

  const sendMessage = (text) => {
    if (text.trim() === "") return;
    const timestamp = new Date().toISOString();
    const params = {
      senderId: user._id,
      recipientId: activeChatInfo._id,
      text,
      chatId: activeChatId,
      senderName: `${user.firstName} ${user.lastName}`,
      recipientName: `${activeChatInfo.firstName} ${activeChatInfo.lastName}`,
      timestamp,
    };

    setMessages((prevMessages) => [...prevMessages, params]);
    setMessageInputValue("");
    socket.emit("call", "chat.sendMessage", params);
  };

  const getRoomMessages = (currentChatId) => {
    setIsFetching(true);
    socket.emit(
      "call",
      "chat.getMessages",
      {
        chatId: currentChatId,
      },
      (err, messagesData) => {
        setMessages(messagesData);
        setIsFetching(false);
      }
    );
  };

  // debounce search
  const debouncedSearch = React.useMemo(
    () => _.debounce((searchInput) => search(searchInput), 500),
    []
  );

  const getConversations = () => {
    socket.emit(
      "call",
      "chat.getConversations",
      { userId: user._id },
      (err, conversationsData) => {
        setConversations(conversationsData);
      }
    );
  };

  const formatConversationCover = (members) => {
    let cover = "";
    members.forEach((member) => {
      if (member.userId !== user._id) {
        cover = `${member.name}`;
      }
    });
    return cover;
  };

  const formatConversationLastMessage = (
    lastSenderName,
    lastMessage,
    lastSenderId
  ) => {
    if (lastMessage) {
      if (lastSenderId === user._id) {
        return "You";
      } else {
        return `${lastSenderName}`;
      }
    }
    return "";
  };

  function onConnect() {
    console.log("Connection established!");
    getConversations();
  }

  const setupListeners = () => {
    socket.on("new_message", (data) => {
      setActiveChatId((prevChatId) => {
        if (data.senderId !== user._id && data.chatId === prevChatId) {
          setMessages((prevMessages) => [...prevMessages, data]);
        }
        return prevChatId;
      });
    });
    socket.on("update_conversation", (data) => {
      setConversations((prevConversations) => {
        // check if user._id exists in members userId
        if (data.members?.find((member) => member.userId === user._id)) {
          if (
            prevConversations &&
            prevConversations.length > 0 &&
            prevConversations?.find(
              (conversation) => conversation.chatId === data.chatId
            )
          ) {
            const newUpdateConversations = prevConversations.map(
              (conversation) => {
                if (conversation.chatId === data.chatId) {
                  return {
                    ...conversation,
                    lastMessage: data.lastMessage,
                    lastSenderId: data.lastSenderId,
                    lastSenderName: data.lastSenderName,
                    timestamp: data.timestamp,
                  };
                }
                return conversation;
              }
            );
            return newUpdateConversations;
          } else {
            return [...prevConversations, data];
          }
        }
      });
      // } else {
      //   console.log("cav2");
      //   setConversations((prevConversations) => [...prevConversations, data]);
      // }
    });

    socket.on("newJoin", (data) => {
      console.log("newJoin", data);
    });
    socket.on("connect", onConnect);
  };

  useEffect(() => {
    setupListeners();
    return () => {
      socket.off("connect", onConnect);
    };
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={{ padding: 0, background: colorBgContainer }}>
        <Content style={{ padding: "15px" }}>
          <Title level={3}>Messages</Title>
        </Content>
      </Header>
      <MainContainer responsive style={{ minHeight: "600px" }}>
        <ChatSidebar
          position="left"
          scrollable={false}
          style={{ minHeight: "620px" }}
        >
          <Search
            placeholder="Search"
            onClearClick={() => {
              setSearchUser([]);
              setSearchInput("");
              setSearchInputPlaceholder("");
            }}
            onChange={(value) => {
              debouncedSearch(value);
              setSearchInputPlaceholder(value);
            }}
            value={searchInputPlaceholder}
          />
          <div>
            {searchInput ? (
              // User is searching for users
              <ConversationList>
                {searchedUser.map((user) => {
                  return (
                    <Conversation
                      key={user._id}
                      onClick={() => openRoom(user)}
                      name={`${user.firstName} ${user.lastName}`}
                      info="Tap to message..."
                    >
                      <Avatar
                        src={defaultAvatar}
                        name="Default"
                        status="available"
                      />
                    </Conversation>
                  );
                })}
              </ConversationList>
            ) : (
              <ConversationList>
                {conversations?.map((conversation) => {
                  return (
                    <Conversation
                      key={conversation._id}
                      onClick={() => openRoom(conversation)}
                      name={formatConversationCover(conversation.members)}
                      lastSenderName={formatConversationLastMessage(
                        conversation.lastSenderName,
                        conversation.lastMessage,
                        conversation.lastSenderId
                      )}
                      info={conversation.lastMessage}
                    >
                      <Avatar
                        src={defaultAvatar}
                        name="Default"
                        status="available"
                      />
                    </Conversation>
                  );
                })}
              </ConversationList>
            )}
          </div>
        </ChatSidebar>

        <ChatContainer>
          {activeChatId ? (
            <ConversationHeader>
              <ConversationHeader.Back />
              <Avatar src={defaultAvatar} name={activeChatInfo.firstName} />
              <ConversationHeader.Content
                userName={
                  activeChatInfo.firstName
                    ? `${activeChatInfo.firstName} ${activeChatInfo.lastName}`
                    : ""
                }
                info={activeChatInfo.email ? `${activeChatInfo.email}` : ""}
              />
              <ConversationHeader.Actions>
                <InfoButton />
              </ConversationHeader.Actions>
            </ConversationHeader>
          ) : null}
          {activeChatId ? (
            <MessageList
              style={{
                height: "500px",
                overflow: "hidden",
              }}
            >
              {isFetching ? (
                <>
                  <Skeleton avatar paragraph={{ rows: 2 }} active />
                  <Skeleton avatar paragraph={{ rows: 2 }} active />
                  <Skeleton avatar paragraph={{ rows: 2 }} active />

                  <Skeleton avatar paragraph={{ rows: 2 }} active />

                  <Skeleton avatar paragraph={{ rows: 2 }} active />
                </>
              ) : (
                // </MessageList>
                <>
                  {messages.map((message) => {
                    return (
                      <Message
                        key={message._id}
                        model={{
                          message: message.text,
                          sentTime: moment(message.timestamp).fromNow(),
                          sender: message.senderName,
                          direction:
                            user._id === message.senderId
                              ? "outgoing"
                              : "incoming",
                          position: "single",
                        }}
                      >
                        <Avatar src={defaultAvatar} name={message.senderName} />
                        <Message.Header
                          sender={message.senderName}
                          sentTime={moment(message.timestamp).fromNow()}
                        />
                      </Message>
                    );
                  })}
                </>
              )}
            </MessageList>
          ) : (
            <span
              style={{
                maxHeight: "600px",
                minHeight: "500px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <div style={{}}>
                <Title level={4}>Start messaging</Title>
              </div>
            </span>
          )}
          {activeChatId ? (
            <MessageInput
              placeholder="Type message here"
              value={messageInputValue}
              onChange={(val) => setMessageInputValue(val)}
              onSend={(innerHtml, text) => sendMessage(text)}
              attachButton={false}
            />
          ) : null}
        </ChatContainer>

        <ChatSidebar position="right">
          <ExpansionPanel open title="INFO">
            <p>{`${activeChatInfo.firstName} ${activeChatInfo.lastName}`}</p>
            <p>{activeChatInfo.email}</p>
            <p>{activeChatInfo.mobileNumber}</p>
          </ExpansionPanel>

          <ExpansionPanel title="OPTIONS">
            <Text delete>Block User</Text>
          </ExpansionPanel>
        </ChatSidebar>
      </MainContainer>
    </Layout>
  );
};

export default Messages;
