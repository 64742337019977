import React, { useState } from "react";
import useAuthStore from "../../store/auth.store";
import useUserStore from "../../store/user.store";
import Swal from "sweetalert2";
import { loginUser } from "../../api/auth.api";
import "./login.scss";

import { Row, Col, Card, Image, Divider } from "antd";

const Login = () => {
  const [email, setUserName] = useState();
  const [password, setPassword] = useState();
  const setLoggedIn = useAuthStore((state) => state.setLoggedIn);
  const setUserData = useUserStore((state) => state.setUserData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await loginUser(email, password);
    if (response.code === 200 || response.data?.success === true) {
      localStorage.setItem("token", response.data.data.token);
      console.log(response);
      setUserData(response.data.data.userData);
      setLoggedIn();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: response.data.message,
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {/* <Affix offsetTop={180} onChange={(affixed) => console.log(affixed)}> */}

      <Card bordered={false} style={{ height: "100vh", width: "800px" }}>
        <Row>
          <Col span={8}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
              }}
            >
              <Image width={200} src="/sider.svg" preview={false} />
            </div>
          </Col>

          <Col span={16}>
            <form className="login" onSubmit={handleSubmit}>
              <h2>
                ANTS - Admin - Login
                <Divider />
              </h2>
              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                placeholder="User Name"
                onChange={(e) => setUserName(e.target.value)}
              />
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />{" "}
              <br></br>
              <input type="submit" name="submit" value="Log In" />
            </form>
          </Col>
        </Row>
      </Card>
      {/* </Affix> */}
    </div>
  );
};

export default Login;
