import axios from "../helpers/axios";

export const createSeller = async (params) => {
  return axios
    .post("/api/seller/create-seller", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const removeSeller = async (id) => {
  return axios
    .delete(`/api/seller/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const editSeller = async (id, params) => {
  return axios
    .put(`/api/seller/${id}`, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const searchSeller = async (search) => {
  return axios
    .post("/api/seller/search", { search })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addSellerToProduct = async (params) => {
  return axios
    .post("/api/seller_products", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const removeSellerFromProduct = async (id) => {
  return axios
    .delete(`/api/seller_products/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
