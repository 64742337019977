import axios from "../helpers/axios";

export const getUser = async () => {
  return axios
    .get("/api/profile?pageSize=99999999")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteUser = async (id) => {
  return axios
    .delete(`/api/auth/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const viewUser = async (id) => {
  return axios
    .post("/api/requestForQuotation/get-rfq-by-id", { id })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

//verify User
export const getnotverifiedUser = async () => {
  return axios
    .get("/api/profile/get-nonverified-users")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
export const getverifiedUser = async () => {
  return axios
    .get("/api/profile/get-verified-users")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const verify = async (id) => {
  return axios
    .post("/api/profile/verified", { id })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

//VIP User
export const getnotvipUser = async () => {
  return axios
    .get("/api/profile/get-nonvip-users")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getvipUser = async () => {
  return axios
    .get("/api/profile/get-vip-users")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const vip = async (id) => {
  return axios
    .post("/api/profile/vip", { id })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getTotal = async () => {
  return axios
    .get("/api/profile/getTotals")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const userSearch = async (search) => {
  return axios
    .post("/api/profile/search", { search })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getSeller = async () => {
  return axios
    .get("api/seller/")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getVip = async () => {
  return axios
    .get("/api/vip/")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
