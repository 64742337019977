import axios from "../helpers/axios";

export const returnedOrder = async (params) => {
  return axios
    .post("/api/order/returned-order", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const requoteOrder = async (params) => {
  return axios
    .post("/api/order/requote-order", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
