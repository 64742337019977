import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { Sidebar } from "../components/sidebar/Sidebar";
import Clock from "react-live-clock";
import { useNavigate } from "react-router-dom";
import { Layout, theme, Menu, Space } from "antd";
import { MessageOutlined, BellOutlined } from "@ant-design/icons";
const { Header, Content, Footer } = Layout;
function getItem(label, key, icon = null, children = null) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem("", "/messages", <MessageOutlined style={{ fontSize: "20px" }} />),

  getItem("", "/notifications", <BellOutlined style={{ fontSize: "20px" }} />),
];

const PrivateRoute = ({ isAllowed, redirectPath = "/login", children }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const menuChanged = (value) => {
    navigate(`${value.key}`);
  };
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  return children ? (
    <Layout>
      <Sidebar />
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            paddingLeft: "10px",
            background: colorBgContainer,
          }}
        >
          <Space
            direction="horizontal"
            style={{ color: "#05aae8", fontWeight: "bolder" }}
          >
            <div>Philippines Time: </div>
            <Clock
              format={"MMMM DD, YYYY, h:mm:ss A"}
              ticking={true}
              timezone={"Asia/Manila"}
            />
          </Space>

          <Menu
            theme="light"
            mode="horizontal"
            defaultSelectedKeys={["2"]}
            items={items}
            onClick={menuChanged}
            style={{ float: "right" }}
          />
        </Header>
        <Content style={{ margin: "0 16px" }}>
          <div style={{ margin: "16px 0" }}></div>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
            }}
          >
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Ants Admin ©2023 Created by We Lead Com Tech
        </Footer>
      </Layout>
    </Layout>
  ) : (
    <Outlet />
  );
};

export default PrivateRoute;
