import React, { lazy, Suspense } from "react";

const EChartsChartLazy = lazy(() => import("./no.ssr.line.data"));

const RfqLine = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <EChartsChartLazy />
    </Suspense>
  );
};

export default RfqLine;
